<template>
  <div
    data-cy="promreport-items"
    class="prom container no-pdt"
    ref="container"
    :class="promClass"
  >
    <card-container
      v-if="promRequest && promRequest.ContextText.length > 0"
      :full-width="true"
    >
      <card>
        <template v-slot:content>
          <div
            class="pd20"
            v-html="$filters['NL2BR'](promRequest.ContextText)"
          ></div>
        </template>
      </card>
    </card-container>
    <card-prom-header :prom="prom" :prom-report="report"></card-prom-header>
    <edit-lock
      v-if="report.isFromRemote"
      :locked="report._locked"
      @change="locked => (report._locked = locked)"
    ></edit-lock>

    <template v-if="activeInfo">
      <card-info
        :item="activeInfo"
        :report="report"
        :readonly="report._locked"
        :item-num="0"
        :affixed="false"
        @setAnswer="params => setAnswer(params)"
        @removeAnswer="removeAnswer"
        @toggleSkippables="toggleSkippables"
      ></card-info>
    </template>
    <s-tooltip
      class="skipped-questions-tooltip"
      arrow-placement="top"
      :width="220"
      :show-icon="false"
      :show-tooltip="skippedQuestionsCount > 0"
      :hide-on-click="false"
      :click-callback="() => scrollToFirstUnansweredQuestion()"
    >
      {{ skippedQuestionsCount }}
      {{ $tc('plural.unansweredQuestions', skippedQuestionsCount) }}
    </s-tooltip>
    <template v-for="(item, index) in items">
      <component
        :is="`Card${item._modelName}`"
        :item="item"
        :report="report"
        :hidden="isHidden(item)"
        :show-hidden-questions-text="style !== styles.CAT_STYLE"
        :readonly="report._locked"
        :key="index"
        :affixed="item._modelName == 'Info' && style == styles.NORMAL_STYLE"
        @setAnswer="setAnswer"
        @removeAnswer="removeAnswer"
        @toggleSkippables="toggleSkippables"
        :item-num="index"
        :has-sub-question="(prom.itemsByIndex[index + 1] || {}).Level === 2"
        v-if="
          (style === styles.CAT_STYLE && currentQuestionIndex === index) ||
          style === styles.NORMAL_STYLE
        "
        ref="questions"
      >
        <flex-grid
          :justify="currentQuestionIndex === 0 ? 'flex-end' : 'space-between'"
          :full-width="true"
          v-if="style === styles.CAT_STYLE"
          :force-row="true"
        >
          <s-button
            @click="goToPreviousQuestion()"
            v-if="currentQuestionIndex > 0"
            id="ButtonPrevious"
            :flat="true"
            :dark="true"
            element-name="promreport-previousbutton"
            >{{ $t('generic.previous') }}</s-button
          >
          <div v-tippy="inactiveText">
            <s-button
              v-if="endOfProm"
              @click="saveReport"
              :primary="true"
              id="ButtonSave"
              element-name="promreport-savebutton"
              :loading="saveLoading"
              :disabled="nextInactive || !savable"
              >{{ $t('generic.save') }}</s-button
            >

            <s-button
              v-else
              @click="goToNextQuestion"
              extra-classes="button--blue"
              id="ButtonNext"
              element-name="promreport-nextbutton"
              :disabled="nextInactive"
            >
              {{ $t('generic.next') }}
            </s-button>
          </div>
        </flex-grid>

        <template v-slot:cancel>
          <div
            class="cancel pointer"
            v-if="style !== styles.NORMAL_STYLE"
            @click="cancel"
          >
            {{ $t('generic.cancel') }}
          </div>
        </template>
      </component>
    </template>

    <flex-grid
      justify="center"
      :full-width="true"
      :force-row="true"
      v-if="
        style === styles.NORMAL_STYLE ||
        (report.isCompleted &&
          currentQuestionIndex + 1 !== prom.QuestionCount) ||
        currentQuestionIndex + 1 > prom.questionCount
      "
    >
      <template v-if="style === styles.NORMAL_STYLE && !report._locked">
        <s-button
          @click="cancel"
          id="ButtonCancel"
          :flat="true"
          :dark="true"
          class="underline"
          element-name="promreport-cancelbutton"
          >{{ $t('generic.cancel') }}</s-button
        >
        <s-button
          @click="saveReport"
          :disabled="!savable"
          :primary="true"
          id="ButtonSave"
          element-name="promreport-savebutton"
          :loading="saveLoading"
          >{{ $t('generic.save') }}</s-button
        >
      </template>
    </flex-grid>

    <modal-confirm
      :show="confirmCancel"
      :accept-callback="
        () => {
          confirmedCancel = true;
          cancel();
        }
      "
      :accept-text="$t('generic.leave')"
      :show-extras="false"
      :decline-text="$t('generic.stay')"
      @close="confirmCancel = false"
    >
      <template v-slot:title>
        <span v-text="$t('modals.confirmLeaveReportTitle')"></span>
      </template>
      {{ $t('modals.confirmLeavePromWarning') }}
      <br />
      <br />
      {{ $t('modals.confirmLeave') }}
    </modal-confirm>
  </div>
</template>

<script>
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import useVuelidate from '@vuelidate/core';
import PromRequestService from '@common/Services/PromRequests/PromRequestService';
import { mapActions, mapGetters } from 'vuex';
import AnswerModel from '@common/Models/AnswerModel';
import QuestionModel from '@common/Models/QuestionModel';
import { PROMREPORT_ACTIONS } from '@/store/modules/promreports';
import skippables from '@/views/PromReport/mixins/skippables';
import Timer from '@common/Helpers/Timer';
import Info from '@common/Components/PromReport/Info';
import EditLock from '@/views/components/EditLock.vue';
import CardPromHeader from '@common/Components/PromReport/CardPromHeader.vue';
import CardQuestion from '@common/Components/PromReport/CardQuestion.vue';
import CardInfo from '@common/Components/PromReport/CardInfo.vue';
import FlexGrid from '@common/Components/Grids/FlexGrid.vue';
import { APP_VERSION_OPTIONS } from '@common/constants';
import { GetErrorCodeMessage } from '@common/Mixins/ErrorCodes';
import { smoothScroll } from '@common/Helpers/html';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {
    prom: {
      type: Object,
      required: true
    },
    promReport: {
      type: Object,
      required: true
    },
    previousPromReport: {
      type: Object,
      required: false
    },
    promRequest: {
      type: Object,
      required: false,
      default: null
    }
  },
  mixins: [skippables, GetErrorCodeMessage],
  components: {
    Info,
    CardPromHeader,
    CardQuestion,
    CardInfo,
    FlexGrid,
    EditLock
  },
  data() {
    return {
      report: this.promReport.__copy(),
      hiddenQuestions: [],
      timer: new Timer(),
      saveLoading: false,
      confirmCancel: false,
      confirmedCancel: false,
      redirectOnCancel: null,
      confirmUnlock: false,
      scrollTimeout: null,
      skippedQuestionsCount: 0,
      scrollToTimer: null,
      isInvalid: true
    };
  },
  watch: {
    promReport(report) {
      this.report = report.__copy();
      this.hiddenQuestions = [];
    },
    previousPromReport: {
      handler(newValue, oldValue) {
        if (this.prom.PrefillPreviousAnswers && this.previousPromReport) {
          this.addAnswersFromPreviousReport();
          this.hideNotApplicableQuestions();
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.report.isFromRemote) {
      this.report._locked = true;
    }

    if (this.prom.PrefillPreviousAnswers && this.previousPromReport) {
      this.addAnswersFromPreviousReport();
    }

    if (this.style === this.styles.NORMAL_STYLE) {
      document
        .querySelector('.content')
        .addEventListener('scroll', this.onScroll);
    }

    this.hideNotApplicableQuestions();

    this.timer.start();
  },
  beforeUnmount() {
    if (
      this.style === this.styles.NORMAL_STYLE &&
      document.querySelector('.content')
    ) {
      document
        .querySelector('.content')
        .removeEventListener('scroll', this.onScroll);
    }
  },
  methods: {
    setScrollTo(item) {
      if (this.scrollToTimer) {
        window.clearTimeout(this.scrollToTimer);
      }
      this.scrollToTimer = setTimeout(() => {
        this.scrollToNextItem(item);
      }, 300);
    },
    ...mapActions('promreports', {
      $addOrUpdateReport: PROMREPORT_ACTIONS.ADD_OR_UPDATE_REPORT
    }),
    onScroll(event) {
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
      }
      this.scrollTimeout = setTimeout(() => {
        const elemTop = event.target.offsetTop;
        const elHeight = event.target.getBoundingClientRect().height;
        const elemBottom = elemTop + elHeight;
        const content = document.querySelector('.content');
        const posy = content.scrollTop;

        this.skippedQuestionsCount = 0;

        const affixedInfo = document.querySelector('.affixed');
        const arrow = document.querySelector('.skipped-questions-tooltip');
        const header = document.querySelector('header.app-header');

        if (affixedInfo) {
          arrow.style.top = `${
            affixedInfo.querySelector('.card__content').clientHeight +
            header.clientHeight +
            125
          }px`;
        } else {
          arrow.style.top = `${header.clientHeight + 125}px`;
        }

        this.unansweredQuestions.forEach(question => {
          if (
            !this.isHidden(question) &&
            this.isBelowCoordinate(question, posy)
          ) {
            this.skippedQuestionsCount++;
          }
        });
      }, 200);
    },
    isBelowCoordinate(question, y) {
      const selector = `#question-sequence-${question.SequenceId}`;
      const element = document.querySelector(selector);
      const elemTop = element.offsetParent.offsetTop;
      const elemHeight = element.getBoundingClientRect().height;
      const elemBottom = elemTop + elemHeight;

      return elemBottom < y;
    },
    cancel() {
      if (this.redirectOnCancel) {
        this.$router.push(this.redirectOnCancel.path);
      } else {
        this.$router.back();
      }
    },
    addAnswersFromPreviousReport() {
      if (this.previousPromReport) {
        this.report.prefillAnswersFromReport(this.previousPromReport);
      }
    },
    hideNotApplicableQuestions() {
      this.prom.sortedItems
        .filter(i => i._modelName === 'Question')
        .forEach(q => {
          if (
            this.report.Answers.find(
              a => a.QuestionId === q.Id && a.NotApplicable
            )
          ) {
            this.hideQuestion(q);
          }
        });
    },
    setAnswer(question, answer, checked, notApplicable = false) {
      this.v$.$touch();

      this.report.addAnswer(
        question,
        answer,
        checked,
        this.timer.time,
        notApplicable
      );
      this.postAnswerAction();
      this.$emit('set-unsaved', this.report);
    },
    /**
     * @param {QuestionModel} question
     * @param {AnswerModel} answer
     */
    removeAnswer(question, answer) {
      this.v$.$touch();

      const isAnsweredPreviously = this.report.Answers.findIndex(
        a => a.QuestionId === question.Id
      );
      if (isAnsweredPreviously === -1) {
        return;
      }

      if (question.MultipleChoice) {
        if (answer) {
          const answerIndex = this.report.Answers[
            isAnsweredPreviously
          ].AnswerIds.indexOf(answer.Id);
          this.report.Answers[isAnsweredPreviously].AnswerIds.splice(
            answerIndex,
            1
          );
        } else {
          this.report.Answers[isAnsweredPreviously].AnswerIds = [];
        }
      }

      if (
        !question.MultipleChoice ||
        (question.MultipleChoice &&
          this.report.Answers[isAnsweredPreviously].AnswerIds.length === 0)
      ) {
        this.report.Answers.splice(isAnsweredPreviously, 1);
      }

      this.postAnswerAction(false);
    },
    saveReport() {
      this.saveLoading = true;
      if (this.promRequest) {
        PromRequestService.fillRequest(
          this.promRequest.PromRequestId,
          this.report
        )
          .then(report => {
            this.report._locked = true;
            this.$emit('save', report);
          })
          .catch(e => {
            this.$debug(e);
            this.saveFailure(e);
          })
          .finally(() => (this.saveLoading = false));
      } else {
        const isFirstPromReport = this.$promReports.length === 0;

        this.$addOrUpdateReport(this.report)
          .then(report => {
            if (isFirstPromReport) {
              this.$bus.$emit('show-thank-you-modal', true);
            }

            this.report._locked = true;
            this.$emit('save', report);
          })
          .catch(e => {
            this.$debug(e);
            this.saveFailure(e);
          })
          .finally(() => (this.saveLoading = false));
      }
    },
    postAnswerAction(reset = true) {
      this.report.saved = false;
      if (reset) {
        this.timer.reset();
      }
    },
    saveSuccess() {
      this.$bus.$emit('toast.display', {
        message: this.$t('views.promReport.savedSuccessfully'),
        status: 'success'
      });
    },
    saveFailure(error) {
      console.debug(error);
      this.$bus.$emit('toast.display', {
        message: this.$_GetErrorCodeMessage(
          'prom',
          error,
          this.$t('generic.saveFailure')
        ),
        status: 'failure'
      });
    },
    scrollToNextItem(item) {
      const DONT_SCROLL = () =>
        this.style === this.styles.CAT_STYLE || item.MultipleChoice === true;

      if (DONT_SCROLL()) {
        return;
      }

      let index = this.items.findIndex(x => x.Id === item.Id);
      if (this.style === this.styles.NORMAL_STYLE) {
        index++;
      }

      if (index >= this.items.length) {
        return;
      }

      const nextQuestion = this.getNextUnansweredQuestion(index);
      if (nextQuestion) {
        const nextInfo = this.findInfoToQuestion(nextQuestion);
        this.scrollToItem(nextInfo || nextQuestion);
      } else {
        const nextItem = this.items.find(x => x.Index > item.Index);
        if (!nextItem) {
          return;
        }
        this.scrollToItem(nextItem);
      }
    },
    findInfoToQuestion(question) {
      // Given a question index we walk back in the array and
      // get the Info item after previous Question if one exists.
      const nextQuestionIndex = this.items.findIndex(i => i.Id === question.Id);
      let item;
      for (let i = nextQuestionIndex; i > 0; i--) {
        const currItem = this.items[i];
        if (i >= nextQuestionIndex) {
          continue;
        }
        if (currItem._modelName === 'Info') {
          item = currItem;
        } else if (currItem._modelName === 'Question') {
          break;
        }
      }
      return item;
    },
    scrollToItem(item) {
      const index = this.items.findIndex(
        x => x._sortableKey === item._sortableKey
      );

      if (index >= this.items.length) {
        return;
      }
      setTimeout(() => {
        const element = document.querySelector(`[data-num="${index}"]`);
        smoothScroll(element, {
          behavior: 'smooth'
        }).then(() => {
          const affixed = document.querySelector('.affixed');
          if (affixed) {
            const height = affixed.clientHeight;
            this.addScrollOffset(height);
          }
        });
      }, 0);
    },
    addScrollOffset(offset) {
      if (offset <= 0) {
        return;
      }
      setTimeout(() => {
        const pos = document.querySelector('.content').scrollTop;
        const subtract = 10;
        document.querySelector('.content').scrollTop = pos - subtract;
        offset = offset - subtract;
        this.addScrollOffset(offset);
      }, 1);
    },
    questionIsAnswered(questionId) {
      return this.report.Answers.find(a => a.QuestionId === questionId);
    },
    getNextUnansweredQuestion(index) {
      const nextQuestion = this.items.find(
        item =>
          item._modelName === 'Question' &&
          this.items.findIndex(ix => item.Id === ix.Id) >= index &&
          !this.questionIsAnswered(item.Id)
      );
      return nextQuestion;
    },
    goToNextQuestion() {
      if (this.nextQuestion === null) {
        return;
      }

      this.$router.push({
        name: this.$router.currentRoute.name,
        params: {
          promreportid: this.$route?.params.promreportid
        },
        query: {
          question: this.nextQuestion.Id
        }
      });
    },
    goToPreviousQuestion() {
      if (this.previousQuestion === null) {
        return;
      }

      this.$router.push({
        name: this.$router.currentRoute.name,
        params: {
          promreportid: this.$route?.params.promreportid
        },
        query: { question: this.previousQuestion.Id }
      });
    },
    scrollToFirstUnansweredQuestion() {
      if (this.unansweredQuestions.length > 0) {
        const q = this.unansweredQuestions[0];
        this.scrollToItem(q);
      }
    }
  },
  computed: {
    ...mapGetters('promreports', {
      $promReports: PROMREPORT_GETTERS.PROM_REPORTS
    }),
    activeInfo() {
      const previousInfo = this.items
        .slice(0, this.currentQuestionIndex)
        .reverse()
        .find(i => i._modelName === 'Info');

      return previousInfo;
    },
    currentQuestionIndex() {
      if (this.currentQuestionId === null) {
        return 0;
      }
      return this.items.findIndex(i => i.Id == this.currentQuestionId);
    },
    currentQuestionId() {
      if (this.$route.query.question) {
        return this.$route.query.question;
      }
      return null;
    },
    items() {
      if (this.prom) {
        if (this.style === this.styles.CAT_STYLE && this.prom.isFirstItemInfo) {
          return this.prom.itemsByIndex.slice(1);
        } else {
          return this.prom.itemsByIndex;
        }
      }
      return [];
    },
    inactiveText() {
      if (!this.canGoToNextQuestion) {
        return this.$t(
          'prom-report.inactiveText.theQuestionHasNotBeenAnswered'
        );
      }
      return null;
    },
    nextInactive() {
      return !this.canGoToNextQuestion;
    },
    canGoToNextQuestion() {
      const currentQuestion = this.items[this.currentQuestionIndex];

      if (this.v$.$invalid) {
        return false;
      }

      if (currentQuestion.Required === false) {
        return true;
      }

      if (currentQuestion._modelName === 'Info') {
        return true;
      }

      const answeredQuestion = this.report.Answers.find(
        x => x.QuestionId === currentQuestion.Id
      );

      return !!answeredQuestion;
    },
    previousQuestion() {
      // Find next question in order when there wasn't any conditional applied.
      for (let i = this.currentQuestionIndex - 1; i > -1; i--) {
        var previousQuestion = this.items[i];

        if (
          previousQuestion._modelName === 'Question' &&
          !this.isHidden(previousQuestion)
        ) {
          // We found a question - use it.
          break;
        } else if (previousQuestion === undefined) {
          // We reached beginning of prom.
          previousQuestion = null;
          break;
        }
      }

      return previousQuestion;
    },
    nextQuestion() {
      const currentQuestion = this.items[this.currentQuestionIndex];

      // Check conditional first if there is one set
      if (this.questionIsAnswered(currentQuestion.Id)) {
        if (currentQuestion.Conditionals) {
          const answer = this.report.Answers.find(
            a => a.QuestionId === currentQuestion.Id
          );

          const condition = currentQuestion.Conditionals.find(c =>
            answer.AnswerIds.includes(c.CompareAnswerId)
          );

          if (condition) {
            if (condition.RedirectQuestionId == 0) {
              // Condition found but redirects to the very end of Prom
              return null;
            } else {
              // Redirect to question
              const redirectQuestion = this.items.find(
                i => i.Id === condition.RedirectQuestionId
              );

              return redirectQuestion;
            }
          }
        }
      }

      var nextQuestion = null;

      // Find next question in order when there wasn't any conditional applied.
      for (let i = this.currentQuestionIndex + 1; i < this.items.length; i++) {
        nextQuestion = this.items[i];

        if (nextQuestion._modelName === 'Question') {
          // We found a question - use it.
          break;
        } else if (nextQuestion === undefined) {
          // We reached end of prom.
          nextQuestion = null;
          break;
        }
      }

      return nextQuestion;
    },
    visibleItems() {
      return this.items.filter(i => !this.isHidden(i));
    },
    savable() {
      if (!this.report) {
        return false;
      }
      return (
        !this.report._locked &&
        this.report.Answers.length > 0 &&
        !this.v$.$invalid
      );
    },
    styles() {
      return APP_VERSION_OPTIONS.PromReportVersions;
    },
    endOfProm() {
      return this.nextQuestion === null;
    },
    promClass() {
      switch (this.style) {
        case this.styles.CAT_STYLE:
          return 'cat';
        default:
          return null;
      }
    },
    style() {
      let style = APP_VERSION_OPTIONS.PromReportVersions.NORMAL_STYLE;

      if (this.prom && this.prom.PromTypeOverride) {
        style = this.prom.PromTypeOverride;
      }

      return style;
    },
    unansweredQuestions() {
      return this.items.filter(
        i => i._modelName === 'Question' && !this.questionIsAnswered(i.Id)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.cat {
  .answers {
    margin-bottom: 20px !important;
  }
}
.card {
  .cancel {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    text-decoration: underline;
    @media only screen and (max-width: $breakpoint-desktop) {
      font-size: 13px;
    }
  }
}
.skipped-questions-tooltip {
  position: fixed !important;
  left: 50.2%;
  top: 200px;
  text-transform: lowercase;

  @media only screen and (max-width: $breakpoint-phone) {
    top: 165px;
  }
}
</style>
