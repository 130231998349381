<template>
  <div>
    <prom-requests></prom-requests>
    <prom-suggestions></prom-suggestions>

    <div style="max-width: 100%">
      <h4 class="sub-header" v-text="$t('views.promReport.allProms')"></h4>
      <card-container>
        <card-scroll>
          <card-prom
            v-for="prom in $allProms"
            :key="prom.Id"
            :prom="prom"
          ></card-prom>
        </card-scroll>
      </card-container>
      <div
        class="row"
        v-if="!$allProms.length"
        v-text="$t('views.promReport.noPromsFound')"
      ></div>
    </div>

    <div v-if="$incompletePromReports.length" style="max-width: 100%">
      <h4 class="sub-header" v-text="$t('views.start.incompleteProms')"></h4>
      <card-container>
        <card-scroll>
          <card-prom-report
            v-for="report in $incompletePromReports"
            :key="report.Id"
            :report="report"
          ></card-prom-report>
        </card-scroll>
      </card-container>
    </div>
  </div>
</template>

<script>
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { mapGetters, mapActions } from 'vuex';
import { PROM_ACTIONS, PROM_GETTERS } from '@/store/modules/proms/definitions';
import PromSuggestions from '@/views/Start/components/PromSuggestions';
import CardProm from '@/views/components/Card/CardProm.vue';
import CardPromReport from '@/views/components/Card/CardPromReport.vue';
import PromRequests from '../Start/components/PromRequests';

export default {
  components: {
    PromSuggestions,
    CardProm,
    CardPromReport,
    PromRequests
  },
  created() {
    this.$getProms();
  },
  methods: {
    ...mapActions('proms', {
      $getProms: PROM_ACTIONS.GET_PROMS
    })
  },
  computed: {
    ...mapGetters('proms', {
      $allProms: PROM_GETTERS.ALL_SHOWN_PROMS
    }),
    ...mapGetters('promreports', {
      $incompletePromReports: PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS
    })
  }
};
</script>

<style lang="css"></style>
