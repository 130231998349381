<template>
  <div class="symptoms-checkbox" :class="{ disabled: $attrs.disabled }">
    <input
      type="checkbox"
      v-bind="$attrs"
      ref="checkbox"
      @change="$emit('value', $event)"
      :checked="checked"
      :class="{ faked }"
    />
    <label :for="$attrs.id">
      <span :class="[color, type]"></span>
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    checked: Boolean,
    faked: Boolean,
    type: String,
    color: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '@sass/_variables.scss';

$fontSize: math.div(20px, 1.4);
$fontSizeDesktop: math.div(24px, 1.4);

.symptoms-checkbox {
  width: 100%;
  position: relative;

  p {
    margin: 0;
  }

  input[type='checkbox'] {
    height: 0;
    width: 0;
  }

  &.disabled {
    input[type='checkbox'] + label {
      color: #9e9e9e;
      cursor: default;
    }

    input[type='checkbox'] + label > span,
    .separator {
      border-color: #e6e6e6 !important;
      cursor: default;
    }
  }

  input[type='checkbox'] + label {
    position: relative;
    display: flex;
    margin: 0.6em 0;
    align-items: center;
    font-size: $fontSize;

    @media only screen and (min-width: $breakpoint-desktop) {
      font-size: $fontSizeDesktop;
    }

    cursor: pointer;
    transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  }

  input[type='checkbox'] + label > span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
    min-width: 2em;
    height: 2em;
    background: transparent;
    border: 2px solid #9e9e9e;
    border-radius: 7px;
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  }

  input[type='checkbox'] + label:hover > span,
  input[type='checkbox']:focus + label > span {
    background: rgba(255, 255, 255, 0.1);
  }

  input[type='checkbox'].faked + label > p,
  input[type='checkbox']:checked + label > p {
    font-weight: 700;
    color: #000;
  }

  input[type='checkbox'].faked + label > span,
  input[type='checkbox']:checked + label > span {
    border: 1em solid $symptoms-blue;
    animation: shrink-bounce 100ms cubic-bezier(0.4, 0, 0.23, 1);

    &.orange {
      border-color: $symptoms-orange !important;
    }
  }

  input[type='checkbox'].faked + label > span:before,
  input[type='checkbox']:checked + label > span:before {
    content: '';
    position: absolute;
    left: 0.45em;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checkbox-check 175ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
  }

  input[type='checkbox'].faked + label > span.radio:before,
  input[type='checkbox']:checked + label > span.radio:before {
    border: 0px solid transparent;
    left: 0.6em;

    @media only screen and (min-width: $breakpoint-desktop) {
      top: 0.75em;
      left: 0.75em;
    }

    border-radius: 100%;
    transform: rotate(0deg);
    transform-origin: 0% 105%;
    animation: radio-check 175ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
  }

  @keyframes shrink-bounce {
    0% {
      transform: scale(1);
    }

    33% {
      transform: scale(0.85);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes checkbox-check {
    0% {
      width: 0;
      height: 0;
      border-color: #ccc;
      transform: translate3d(0, 0, 0) rotate(45deg);
    }

    33% {
      width: 0.2em;
      height: 0.3em;
      transform: translate3d(0, 0, 0) rotate(45deg);
    }

    100% {
      width: 0.6em;
      height: 1em;
      border-color: #fff;
      transform: translate3d(0, -0.5em, 0) rotate(45deg);
    }
  }

  @keyframes radio-check {
    0% {
      border-color: #ccc;
    }

    33% {
      border: 2px solid #ccc;
    }

    100% {
      border: 5px solid #fff;
    }
  }
}
</style>
