export const CURRENT_USER_MUTATIONS = {
  SET_APP_VERSION: 'SET_APP_VERSION',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_MODEL: 'SET_MODEL',
  ADD_SHARE: 'ADD_SHARE',
  REMOVE_SHARE: 'REMOVE_SHARE',
  SET_PROFILE: 'SET_PROFILE',
  SET_USER: 'SET_USER',
  SET_ACTIVE_SESSIONS: 'SET_ACTIVE_SESSIONS',
  SET_HAS_REPORTED_STATUS: 'SET_HAS_REPORTED_STATUS',
  SET_ACTIVE_GROUP: 'SET_ACTIVE_GROUP',
  SET_MY_GROUPS: 'SET_MY_GROUPS',
  ACCEPT_TOS: 'ACCEPT_TOS',
  SET_PROMREQUESTS: 'SET_PROMREQUESTS',
  ADD_PROMREQUEST: 'ADD_PROMREQUEST',
  REMOVE_PROMREQUEST: 'REMOVE_PROMREQUEST',
  TOGGLE_2FA: 'TOGGLE_2FA',
  REMOVE_SESSION: 'REMOVE_SESSION',
  TOGGLE_AUTO_SCROLL: 'TOGGLE_AUTO_SCROLL'
};

export const CURRENT_USER_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_PROFILE_VIEW: 'GET_PROFILE_VIEW',
  GET_PROFILE: 'GET_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  SET_HAS_SEEN_TUTORIAL: 'SET_HAS_SEEN_TUTORIAL',
  GET_USER: 'GET_USER',
  UPDATE_USER: 'UPDATE_USER',
  GET_ACTIVE_SESSIONS: 'GET_ACTIVE_SESSIONS',
  DESTROY_SESSION: 'DESTROY_SESSION',
  LOGOUT_OTHER_DEVICES: 'LOGOUT_OTHER_DEVICES',
  SET_LANGUAGE: 'SET_LANGUAGE',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  CHANGE_SYMPTOM_MODEL: 'CHANGE_SYMPTOM_MODEL',
  SET_ACTIVE_GROUP: 'SET_ACTIVE_GROUP',
  GET_MY_GROUPS: 'GET_MY_GROUPS',
  TOGGLE_SHARE_SETTING: 'TOGGLE_SHARE_SETTING',
  REMOVE_ACCOUNT: 'REMOVE_ACCOUNT',
  ANONYMISE_ACCOUNT: 'ANONYMISE_ACCOUNT',
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
  DEACTIVATE_ACCOUNT: 'DEACTIVATE_ACCOUNT',
  GET_2FA_SETUP: 'GET_2FA_SETUP',
  REMOVE_2FA: 'REMOVE_2FA',
  REFRESH_2FA: 'REFRESH_2FA',
  AUTHENTICATE_2FA: 'AUTHENTICATE_2FA',
  AUTHENTICATE_2FA_BACKUP_CODE: 'AUTHENTICATE_2FA_BACKUP_CODE',
  GET_PROMREQUESTS: 'GET_PROMREQUESTS',
  GET_PROMREQUEST: 'GET_PROMREQUEST',
  DOWNLOAD_DATA: 'DOWNLOAD_DATA',
  CREATE_NEW_PATIENT: 'CREATE_NEW_PATIENT',
  TRIGGER_2FA_MODAL: 'TRIGGER_2FA_MODAL'
};

export const CURRENT_USER_GETTERS = {
  PROFILE: 'PROFILE',
  PROFILE_COPY: 'PROFILE_COPY',
  USER: 'USER',
  USER_COPY: 'USER_COPY',
  GET_ACTIVE_SYMPTOM_MODEL: 'GET_ACTIVE_SYMPTOM_MODEL',
  GET_SYMPTOM_MODEL: 'GET_SYMPTOM_MODEL',
  ACTIVE_LANGUAGE: 'ACTIVE_LANGUAGE',
  GROUP_DETAILS: 'GROUP_DETAILS',
  ACTIVE_SYMPTOM_TYPES: 'ACTIVE_SYMPTOM_TYPES',
  ACTIVE_SESSIONS: 'ACTIVE_SESSIONS',
  TOS_STATUS: 'TOS_STATUS',
  SHARE_SETTINGS: 'SHARE_SETTINGS',
  ACTIVE_GROUP: 'ACTIVE_GROUP',
  MY_GROUPS: 'MY_GROUPS',
  MY_GROUPS_WITH_PATIENT_ROLE: 'MY_GROUPS_WITH_PATIENT_ROLE',
  PROMREQUESTS: 'PROMREQUESTS',
  INCOMPLETE_PROMREQUESTS: 'INCOMPLETE_PROMREQUESTS',
  IS_PROMREQUEST: 'IS_PROMREQUEST',
  TFA_NEEDED: 'TFA_NEEDED',
  STATS: 'STATS',
  HAS_REPORTED_STATUS: 'HAS_REPORTED_STATUS',
  HAS_SEEN_TUTORIAL: 'HAS_SEEN_TUTORIAL',
  AUTO_SCROLL: 'AUTO_SCROLL',
  ADVANCED_MANIKIN: 'ADVANCED_MANIKIN',
  APP_VERSION: 'APP_VERSION'
};
