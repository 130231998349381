import ContractModel from '@common/Models/ContractModel';
import ContractService from '@common/Services/Contracts/ContractService';
import {
  CONSENTS_ACTIONS,
  CONSENTS_GETTERS,
  CONSENTS_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {ContractModel[]} */
    this.consents = [];
    this.working = false;
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /**
   * Add consent
   * @param {ContractModel} consent
   */
  [CONSENTS_MUTATIONS.ADD_CONSENT](state, consent) {
    const exists = state.consents.find(n => n.Id === consent.Id);

    if (!exists) {
      state.consents.push(consent);
    } else {
      Object.assign(exists, consent);
    }
  },

  /**
   * Set consent
   * @param {ContractModel[]} consents
   */
  [CONSENTS_MUTATIONS.SET_CONSENTS](state, consents) {
    state.consents = consents;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [CONSENTS_ACTIONS.$PREINIT](state) {},

  [CONSENTS_ACTIONS.GET_CONTRACTS](context) {
    return ContractService.getMyGroupContractsByLanguage().then(consents =>
      context.commit(CONSENTS_MUTATIONS.SET_CONSENTS, consents)
    );
  },

  [CONSENTS_ACTIONS.GET_MY_CONSENTS](context) {
    return ContractService.getMyConsents().then(consents =>
      context.commit(CONSENTS_MUTATIONS.SET_CONSENTS, consents)
    );
  },

  [CONSENTS_ACTIONS.WITHDRAW_CONSENT](context, { consent, anonymize = true }) {
    return ContractService.withdrawMyGroupConsent(consent.Id, anonymize).then(
      contract => {
        context.commit(CONSENTS_MUTATIONS.ADD_CONSENT, contract);
      }
    );
  },

  [CONSENTS_ACTIONS.APPROVE_CONSENT](context, consent) {
    return ContractService.consentToMyGroupContract(consent.Id).then(
      contract => {
        // Make sure we clear WithdrawDate in case it's not the first
        // time this contract is consented to.
        contract.WithdrawDate = null;
        context.commit(CONSENTS_MUTATIONS.ADD_CONSENT, contract);
      }
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  /**
   * Get consents
   * @param {State} state
   */
  [CONSENTS_GETTERS.CONSENTS](state) {
    return state.consents;
  }
};

export {
  CONSENTS_ACTIONS,
  CONSENTS_GETTERS,
  CONSENTS_MUTATIONS
} from './definitions';

export default store;
