export const CONSENTS_MUTATIONS = {
  ADD_CONSENT: 'ADD_CONSENT',
  SET_CONSENTS: 'SET_CONSENTS'
};

export const CONSENTS_ACTIONS = {
  $PREINIT: '$PREINIT',
  GET_CONTRACTS: 'GET_CONTRACTS',
  GET_MY_CONSENTS: 'GET_MY_CONSENTS',
  WITHDRAW_CONSENT: 'WITHDRAW_CONSENT',
  APPROVE_CONSENT: 'APPROVE_CONSENT'
};

export const CONSENTS_GETTERS = {
  CONSENTS: 'CONSENTS'
};
