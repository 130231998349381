import ContractModel from '@common/Models/ContractModel';
import AnonymizeAccountModel from '@common/Models/AnonymizeAccountModel';
import BaseService from '../BaseService';
import {
  ContractResponse,
  ContractsResponse
} from './mappers/ContractResponses';

class ContractService extends BaseService {
  /**
   * Get contracts in active user's group -- identity
   * @returns {Promise<ContractModel[]>}
   * @memberof ContractService
   */
  getMyGroupContracts() {
    return this.identity('get', 'my/group/contracts').then(response =>
      ContractsResponse(response.data)
    );
  }

  /**
   * Get contracts in active user's group for a specified language -- identity
   * @param {string} language LanguageId
   * @returns {Promise<ContractModel[]>}
   * @memberof ContractService
   */
  getMyGroupContractsByLanguage(language = 'sv') {
    return this.identity('get', `my/group/contracts/${language}`).then(
      response => ContractsResponse(response.data)
    );
  }

  /**
   * Get contracts for an invite with specified language -- identity
   * @param {string} inviteKey
   * @param {string} [language='sv']
   * @returns {Promise<ContractModel[]>}
   * @memberof ContractService
   */
  getContractsFromInvite(inviteKey, language = 'sv') {
    return this.identity(
      'get',
      `invites/${inviteKey}/contracts/${language}`
    ).then(response => ContractsResponse(response.data));
  }

  /**
   * Get unconsented contracts for active user's group for a specified language -- identity
   * @param {string} language LanguageId
   * @returns {Promise<ContractModel[]>}
   * @memberof ContractService
   */
  getUnconsentedContracts(language = 'sv') {
    return this.identity(
      'get',
      `my/group/contracts/unconsented/${language}`
    ).then(response => ContractsResponse(response.data));
  }

  /**
   * Get consented contracts for active user's group -- identity
   * @returns {Promise<ContractModel[]>}
   * @memberof ContractService
   */
  getMyConsents() {
    return this.identity('get', 'my/group/contracts/consents').then(response =>
      ContractsResponse(response.data)
    );
  }

  /**
   * Consent to multiple contracts -- identity
   * @param {string[]} dto.ConsentedContracts
   * @returns {Promise<ContractModel[]>}
   * @memberof ContractService
   */
  consentToMyGroupContracts(dto) {
    return this.identity('post', `my/group/contracts/consents`, dto).then(
      response => ContractsResponse(response.data)
    );
  }

  /**
   * Consent to a contract in active user's group -- identity
   * @param {string} ContractId Contract Id
   * @returns {Promise<ContractModel[]>}
   * @memberof ContractService
   */
  consentToMyGroupContract(ContractId) {
    return this.identity(
      'post',
      `my/group/contracts/${ContractId}/consent`,
      {}
    ).then(response => ContractResponse(response.data));
  }

  /**
   * Withdraw contract consent -- identity
   * @param {string} ContractId Contract ID
   * @param {AnonymizeAccountModel} Anonymize Anonymize account
   * @returns {Promise<ContractModel>}
   * @memberof ContractService
   */
  withdrawMyGroupConsent(ContractId, Anonymize = false) {
    return this.identity(
      'post',
      `/my/group/contracts/${ContractId}/withdraw`,
      Anonymize
    ).then(response => ContractResponse(response.data));
  }

  /**
   * Create a contract in active user's group -- identity
   * @param {ContractModel} contract Contract
   * @returns {Promise<ContractModel>}
   * @memberof ContractService
   */
  createMyGroupContract(contract) {
    return this.identity('post', `my/group/contracts`, contract).then(
      response => ContractResponse(response.data)
    );
  }

  /**
   * Update a contract -- identity
   * @param {string} contractId Contract ID
   * @param {ContractModel} contract Contract
   * @returns {Promise<ContractModel>}
   * @memberof ContractService
   */
  updateContract(contractId, contract) {
    return this.identity('put', `contracts/${contractId}`, contract).then(
      response => ContractResponse(response.data)
    );
  }

  /**
   * Delete contract -- backend
   * @param {string} guid Contract id
   * @returns {Promise}
   * @memberof ContractService
   */
  deleteContract(contract) {
    return this.identity('delete', `contracts/${contract.ContractId}`);
  }
}

export default new ContractService();
