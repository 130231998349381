<template>
  <div class="container" data-cy="outstanding-consents">
    <div v-if="!contractsLoading">
      {{ $t('views.tos.acceptToContinue') }}
      <div class="ml20 mt10" v-for="contract in contracts" :key="contract.Id">
        <label :for="contract.Id">
          <input
            type="checkbox"
            :checked="contract._accepted"
            :id="contract.Id"
            class="filled-in"
            @keyup.space.prevent="toggleContract(contract)"
            @click.prevent="toggleContract(contract)"
          />
          <span @click.prevent="toggleContract(contract)"
            >{{ $t('generic.accept') }} {{ contract.Title }}

            <span
              @click="
                showContract = true;
                activeContract = contract;
              "
              v-if="contract.RequiredRead === false"
              class="pointer showcontract ml5"
              >({{ $t('generic.show') }})</span
            >
          </span>
        </label>
      </div>

      <div class="button-container mt20">
        <s-button
          @click.prevent="$router.push('/logout')"
          class="underline"
          :small="true"
          >{{ $t('generic.cancel') }}</s-button
        >
        <s-button
          :small="true"
          @click.prevent="acceptContracts"
          :loading="loading"
          :disabled="!isRequestValid"
          extra-classes="right"
          color="blue"
          >{{ $t('generic.next') }}</s-button
        >
      </div>
    </div>
    <div class="center-text" v-else>
      <loader-vue color="black"></loader-vue>
      <div>{{ $t('generic.loading') }}</div>
    </div>
    <modal
      :show="showContract"
      @close="
        showContract = false;
        activeContract = null;
      "
      :scroll-to-accept="activeContract && activeContract.RequiredRead === true"
      :accept-callback="
        () => {
          activeContract._accepted = true;
          showContract = false;
        }
      "
      :hide-accept-button="
        activeContract && activeContract.RequiredRead === false
      "
      :accept-text="$t('generic.accept')"
      :decline-text="$t('generic.close')"
      :disable-close-icon="true"
      width="80vw"
      max-width="800px"
    >
      <contract :contract="activeContract"></contract>

      <template v-slot:extras>
        <div>
          <dl class="list small-font" v-if="activeContract">
            <dt>
              {{ $t('generic.validFrom') }}:
              {{ $filters.DateFormat(activeContract.ValidFromDate) }}
            </dt>
          </dl>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Contract from '@common/Components/Consents/Contract';
import NewContractConsentModel from '@common/Models/NewContractConsentModel';
import ContractService from '@common/Services/Contracts/ContractService';
import { mapActions, mapMutations } from 'vuex';
import { AUTH_ACTIONS } from '@/store/modules/auth';
import { APP_ACTIONS, APP_MUTATIONS } from '@/store/modules/app';
import LoaderVue from '@common/Components/Loader.vue';

export default {
  components: { Contract, LoaderVue },
  mounted() {
    this.$setPageSettings({ option: 'show_menu', value: false });
    this.$setPageSettings({ option: 'show_header', value: true });
    this.getToS();
  },
  data() {
    return {
      activeContract: null,
      contracts: [],
      contractConsents: [],
      loading: false,
      showContract: false,
      contractsLoading: false
    };
  },
  methods: {
    ...mapActions('app', {
      $preinit: APP_ACTIONS.$PREINIT
    }),
    ...mapActions('auth', {
      $requestNewToken: AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN
    }),
    ...mapMutations('app', {
      $setPageSettings: APP_MUTATIONS.SET_PAGE_SETTING
    }),
    getToS() {
      this.contractsLoading = true;
      ContractService.getUnconsentedContracts()
        .then(contracts => {
          this.contracts = contracts;
        })
        .finally(() => (this.contractsLoading = false));
    },
    toggleContract(contract) {
      if (contract.RequiredRead === false) {
        contract._accepted = !contract._accepted;
        return;
      }
      if (contract._accepted === true) {
        contract._accepted = false;
      } else {
        this.showContract = true;
        this.activeContract = contract;
      }
    },
    acceptContracts() {
      this.loading = true;

      this.contractConsents = this.contracts.map(c => {
        return new NewContractConsentModel({
          ContractLanguageVersionId: c.Id,
          Consented: c._accepted
        });
      });

      return ContractService.consentToMyGroupContracts({
        ConsentedContracts: this.contractConsents
      })
        .then(() => {
          this.$requestNewToken({ force: true }).then(() => {
            this.$preinit(true);
            this.$setPageSettings({ option: 'show_menu', value: true });
            this.$setPageSettings({ option: 'show_header', value: true });
            this.$router.replace({
              path: 'choosemannequin',
              query: { redirect: this.redirectPath }
            });
          });
        })
        .catch(error => {
          console.log('Error:', error);
        })
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    contractGuids() {
      return this.contracts
        .filter(contract => contract._accepted === true)
        .map(c => c.Id);
    },
    isRequestValid() {
      if (!this.contracts) {
        return;
      }
      const allContractsAreAccepted = this.contracts.every(
        contract => contract._accepted === true || contract.Required === false
      );
      return allContractsAreAccepted;
    },
    redirectPath() {
      return this.$route.query?.redirect;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.showcontract:hover {
  text-decoration: underline;
}

.container {
  @media only screen and (min-width: $breakpoint-desktop) {
    max-width: 500px;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
