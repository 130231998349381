<template>
  <div class="container">
    <status-message
      icon="mdi-check-circle"
      :message="$t('views.statusReport.savedSuccessfully')"
      data-cy="statusreport-followup-message-saved"
    ></status-message>
    <div class="box mt20">
      <div
        class="center-text pdtb5"
        v-text="$t('views.statusReport.diagramOverview')"
      ></div>
      <status-graph
        @click="$router.push({ name: 'statusReportGraph' })"
      ></status-graph>
      <i class="mdi mdi-information pointer" @click="showInfo = true"></i>
    </div>
    <followup-actions
      :show-skip="false"
      @followups-available="val => (followupsAvailable = val)"
    ></followup-actions>

    <float-content transition="slideup" :show="showSkip">
      <button-home
        class="underline"
        :dark="true"
        :flat="true"
        :text="
          followupsAvailable
            ? $t('views.statusReport.noFollowup')
            : $t('links.home')
        "
        data-cy="statusreport-followup-homebutton"
      ></button-home>
    </float-content>

    <scroll-end @visible="showSkip = true" container=".content"></scroll-end>

    <modal-status-graph-info
      @close="showInfo = false"
      :show="showInfo"
    ></modal-status-graph-info>
  </div>
</template>

<script>
import StatusMessage from '@/views/components/StatusMessage';
import FollowupActions from './components/FollowupActions';
import StatusGraph from '@/views/StatusReport/components/StatusGraph.vue';
import ModalStatusGraphInfo from '../modals/ModalStatusGraphInfo.vue';
import ScrollEnd from '@common/Components/ScrollEnd.vue';

export default {
  components: {
    StatusMessage,
    StatusGraph,
    FollowupActions,
    ModalStatusGraphInfo,
    ScrollEnd
  },
  data() {
    return {
      followupsAvailable: true,
      showInfo: false,
      showSkip: false
    };
  },
  methods: {},
  computed: {}
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

.mdi-information {
  position: absolute;
  top: -20px;
  right: 20px;
}
.container .mdi-information {
  top: 30px;
  @media only screen and (max-width: $breakpoint-phone) {
    top: 60px;
  }
}
</style>
