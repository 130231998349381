<template>
  <div>
    <div class="container">
      <h4 class="header pb20" v-text="$t('pageTitles.consents')"></h4>
      <p
        v-html="
          $t('views.consents.consentsApprovedViaMinForskningText', {
            url: `<a href='https://minforskning.se' class='underline' target='_blank'>minforskning.se</a>`
          })
        "
      />

      <info-row
        v-for="(consent, index) in $consents"
        :key="index"
        @click="
          activeConsent = consent;
          showConsent = true;
        "
        class="pointer"
      >
        <template v-slot:title>
          <span v-text="consent.Title"></span>
        </template>
        <template v-slot:image>
          <i class="mdi mdi-file-document-edit"></i>
        </template>

        <template v-slot:buttons>
          <i
            class="mdi mdi-check-circle green-text"
            v-if="consent.isConsented"
          ></i>

          <i class="mdi mdi-close-circle red-text" v-else></i>
        </template>

        <template v-slot:date>
          <span
            v-if="consent.ConsentDate"
            :title="$filters.DateTimeFormat(consent.ConsentDate)"
            >{{ $t('views.consents.approved') }}
            {{ $filters.DateFormat(consent.ConsentDate) }}</span
          >
          <span v-else>{{ $t('views.consents.notApproved') }}</span>
          <template v-if="consent.WithdrawDate">
            -
            <span :title="$filters.DateTimeFormat(consent.WithdrawDate)">{{
              $filters.DateFormat(consent.WithdrawDate)
            }}</span>
          </template>
        </template>
      </info-row>

      <modal
        v-if="activeConsent"
        :show="showConsent"
        width="80vw"
        max-width="800px"
        @close="
          activeConsent = null;
          showConsent = false;
        "
        :decline-text="$t('generic.close')"
      >
        <contract :contract="activeConsent"></contract>

        <template v-slot:right-button>
          <div>
            <s-button
              v-if="activeConsent.isConsented"
              :danger="true"
              @click.prevent="
                showConsent = false;
                withdrawConsent(activeConsent);
              "
              >{{ $t('views.consents.withdraw') }}</s-button
            >
            <s-button
              v-else
              :safe="true"
              @click.prevent="
                showConsent = false;
                $approveConsent(activeConsent);
              "
              >{{ $t('views.consents.approve') }}</s-button
            >
          </div>
        </template>

        <template v-slot:extras>
          <div>
            <flex-grid-vue
              :force-row="true"
              justify="space-between"
              class="small-font"
              v-if="activeConsent"
              style="padding: 0; margin: 10px 0"
            >
              <div>
                {{ $t('generic.validFrom') }}:
                {{ $filters.DateFormat(activeConsent.ValidFromDate) }}
              </div>
              <div v-if="activeConsent.isConsented">
                {{ $t('views.consents.consented') }}:
                {{ $filters.DateFormat(activeConsent.ConsentDate) }}
              </div>
            </flex-grid-vue>
          </div>
        </template>
      </modal>

      <modal
        :show="withdrawConsentConfirm"
        @close="withdrawConsentConfirm = false"
        height="auto"
        :decline-text="$t('generic.no')"
      >
        <h5 class="center-text sub-header">
          {{ $t('views.consents.withdrawConsent') }}
        </h5>
        <p>{{ $t('views.consents.info') }}</p>

        <template v-slot:extras>
          <div>{{ $t('views.consents.doYouWantToContinue') }}</div>
        </template>

        <template v-slot:right-button>
          <div>
            <s-button
              :danger="true"
              @click.prevent="
                withdrawConsentConfirm = false;
                finalWithdrawConfirm = true;
              "
              >{{ $t('generic.yes') }}</s-button
            >
          </div>
        </template>
      </modal>

      <modal-anonymise-account
        :anonymise-function="
          anonymizeData => withdrawRequiredConsent(anonymizeData)
        "
        :show="finalWithdrawConfirmAnonymize"
        @close="finalWithdrawConfirmAnonymize = false"
      ></modal-anonymise-account>

      <modal
        :show="finalWithdrawConfirm"
        @close="finalWithdrawConfirm = false"
        height="auto"
        :decline-text="$t('generic.no')"
        cancel-classes="green"
      >
        <h5 class="center-text sub-header">
          {{ $t('views.consents.areYouSure') }}
        </h5>
        <p>{{ $t('views.consents.noReversalPossible') }}</p>

        <p>
          <label for="AnonymizeData">
            <input type="checkbox" id="AnonymizeData" v-model="anonymizeData" />
            <span>{{ $t('views.consents.anonymizeData') }}</span>
          </label>
        </p>

        <template v-slot:extras>
          <div>{{ $t('views.consents.doYouWantToContinue') }}</div>
        </template>

        <template v-slot:right-button>
          <div>
            <s-button :danger="true" @click.prevent="withdrawRequiredConsent"
              >{{ $t('generic.yes') }}!</s-button
            >
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CONSENTS_ACTIONS, CONSENTS_GETTERS } from '@/store/modules/consents';
import { CURRENT_USER_ACTIONS } from '@/store/modules/currentuser';
import Contract from '@common/Components/Consents/Contract';
import InfoRow from '@common/Components/InfoRow/InfoRow';
import ModalAnonymiseAccount from '@/views/modals/ModalAnonymiseAccount.vue';
import AnonymizeAccountModel from '@common/Models/AnonymizeAccountModel';
import FlexGridVue from '@common/Components/Grids/FlexGrid.vue';

export default {
  components: {
    Contract,
    InfoRow,
    ModalAnonymiseAccount,
    FlexGridVue
  },
  mounted() {
    this.$getContracts();
  },
  data: () => ({
    anonymizeData: false,
    activeConsent: null,
    showConsent: false,
    withdrawConsentConfirm: false,
    finalWithdrawConfirm: false,
    finalWithdrawConfirmAnonymize: false,
    approveConsentConfirm: false
  }),
  methods: {
    ...mapActions('consents', {
      $getContracts: CONSENTS_ACTIONS.GET_CONTRACTS,
      $withdrawConsent: CONSENTS_ACTIONS.WITHDRAW_CONSENT,
      $approveConsent: CONSENTS_ACTIONS.APPROVE_CONSENT
    }),
    ...mapActions('currentuser', {
      $removeAccount: CURRENT_USER_ACTIONS.REMOVE_ACCOUNT
    }),
    withdrawConsent() {
      if (this.activeConsent.Required) {
        this.withdrawConsentConfirm = true;
      } else {
        const data = {
          consent: this.activeConsent,
          anonymize: new AnonymizeAccountModel({})
        };
        this.$withdrawConsent(data);
      }
    },
    showFinalWithdrawRequiredConsent() {
      if (!this.anonymizeData) {
        return this.withdrawRequiredConsent().then(() => {
          this.finalWithdrawConfirm = false;
        });
      }
      this.finalWithdrawConfirm = false;
      this.finalWithdrawConfirmAnonymize = true;
    },
    /** @param {AnonymizeAccountModel} anonymizeData */
    withdrawRequiredConsent(anonymizeData = new AnonymizeAccountModel({})) {
      if (this.activeConsent.Reference) {
        anonymizeData.Reference = this.activeConsent.Reference;
      }

      if (this.anonymizeData === true) {
        anonymizeData.Anonymize = true;
      }

      const data = {
        consent: this.activeConsent,
        anonymize: anonymizeData
      };

      return this.$withdrawConsent(data)
        .then(() => {
          if (this.activeConsent.Required === true) {
            this.$router.push({ name: 'logout' });
          }
        })
        .catch(() => {
          this.finalWithdrawConfirm = false;
          this.finalWithdrawConfirmAnonymize = false;
          this.withdrawConsentConfirm = false;
          this.showConsent = false;
        });
    }
  },
  computed: {
    ...mapGetters('consents', {
      $consents: CONSENTS_GETTERS.CONSENTS
    })
  }
};
</script>

<style lang="scss" scoped>
.list {
  margin: 10px 0;
}
.list:not(:last-of-type) {
  border-bottom: 1px solid #000;
  padding-bottom: 7px;
}
</style>
