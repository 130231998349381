<template>
  <nav class="main-menu" data-cy="main-menu">
    <ul>
      <li>
        <router-link
          :to="{ name: 'start' }"
          id="MenuLinkStart"
          data-cy="menu-start"
        >
          <i class="mdi mdi-home"></i>
          <span v-text="$t('components.menuOptions.home')"></span>
        </router-link>
      </li>

      <li v-if="settings.modules.notes.enabled">
        <router-link
          :to="{ name: 'notes' }"
          id="MenuLinkNotes"
          data-cy="menu-notes"
        >
          <i class="mdi mdi-plus-circle-outline"></i>
          <span v-text="$t('components.menuOptions.notes')"></span>
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ name: 'symptomReport' }"
          data-cy="menu-symptomreport"
        >
          <i class="mdi mdi-human"></i>
          <span v-text="$t('components.menuOptions.reportSymptoms')"></span>
        </router-link>
      </li>

      <li v-if="settings.modules.proms.enabled" class="hidden-mobile">
        <router-link
          :to="{ name: 'promReportList' }"
          id="MenuLinkProms"
          data-cy="menu-proms"
        >
          <i class="mdi mdi-clipboard-text"></i>
          <div style="position: relative">
            {{ $t('components.menuOptions.proms') }}
            <badge-vue
              :badge="$promrequests.length"
              :top="-10"
              :left="-35"
            ></badge-vue>
          </div>
        </router-link>
      </li>

      <li class="hidden-mobile">
        <router-link
          :to="{ name: 'graphList' }"
          id="MenuLinkGraphs"
          data-cy="menu-graphs"
        >
          <i class="mdi mdi-chart-timeline-variant"></i>
          <span v-text="$t('components.menuOptions.graphs')"></span>
        </router-link>
      </li>

      <li v-if="settings.modules.calendar.enabled" class="hidden-mobile">
        <router-link
          :to="{ name: 'calendar' }"
          id="MenuLinkCalendar"
          data-cy="menu-calendar"
        >
          <i class="mdi mdi-calendar"></i>
          <span v-text="$t('components.menuOptions.calendar')"></span>
        </router-link>
      </li>

      <li v-if="settings.modules.history.enabled" class="hidden-mobile">
        <router-link
          :to="{ name: 'history' }"
          id="MenuLinkHistory"
          data-cy="menu-history"
        >
          <i class="mdi mdi-history"></i>
          <span v-text="$t('components.menuOptions.history')"></span>
        </router-link>
      </li>

      <li
        @click="toggleLargeMenu()"
        class="hidden-desktop"
        data-cy="menu-more-mobile"
      >
        <a :class="{ active: $showLargeMenu }">
          <i class="mdi mdi-dots-horizontal"></i>
          <span class="relative">
            {{ $t('components.menuOptions.more') }}
            <badge-vue
              :badge="totalNumberNotices"
              style="font-size: 11px"
              :top="-25"
              :right="1"
            ></badge-vue>
          </span>
        </a>
      </li>
    </ul>
    <teleport to="#modal">
      <transition name="slideright">
        <div
          class="more-menu hidden-desktop"
          v-show="$showLargeMenu"
          data-cy="extended-menu"
        >
          <ul>
            <li style="z-index: 10">
              <router-link
                :to="{ name: 'profile' }"
                data-cy="menu-profile-mobile"
              >
                <i class="mdi mdi-account-circle-outline"></i>
                <span v-text="$t('components.menuOptions.myProfile')"></span>
              </router-link>
            </li>
            <li v-if="settings.modules.notes.enabled" style="z-index: 9">
              <router-link :to="{ name: 'notes' }" data-cy="menu-notes-mobile">
                <i class="mdi mdi-book-open-page-variant"></i>
                <span v-text="$t('components.menuOptions.notes')"></span>
              </router-link>
            </li>
            <li v-if="settings.modules.proms.enabled" style="z-index: 8">
              <router-link
                :to="{ name: 'promReportList' }"
                data-cy="menu-proms-mobile"
              >
                <i class="mdi mdi-clipboard-text"></i>
                <div style="position: relative">
                  <span v-text="$t('components.menuOptions.proms')"></span>
                  <badge-vue
                    :badge="$promrequests.length"
                    :top="-63"
                    :right="17"
                  ></badge-vue>
                </div>
              </router-link>
            </li>
            <li style="z-index: 8">
              <router-link
                :to="{ name: 'graphList' }"
                id="MenuLinkGraphs"
                data-cy="menu-graphs"
              >
                <i class="mdi mdi-chart-timeline-variant"></i>
                <span v-text="$t('components.menuOptions.graphs')"></span>
              </router-link>
            </li>
            <!-- <li style="z-index: 7">
              <router-link :to="{ name: 'consents' }" data-cy="menu-consents-mobile">
                <i class="mdi mdi-format-list-checks"></i>
                <span v-text="$t('components.menuOptions.consents')"></span>
              </router-link>
            </li>-->
            <li v-if="settings.modules.calendar.enabled" style="z-index: 6">
              <router-link
                :to="{ name: 'calendar' }"
                data-cy="menu-calendar-mobile"
              >
                <i class="mdi mdi-calendar"></i>
                <span v-text="$t('components.menuOptions.calendar')"></span>
              </router-link>
            </li>
            <li v-if="settings.modules.history.enabled" style="z-index: 6">
              <router-link
                :to="{ name: 'history' }"
                data-cy="menu-history-mobile"
              >
                <i class="mdi mdi-history"></i>
                <span v-text="$t('components.menuOptions.history')"></span>
              </router-link>
            </li>
            <li style="z-index: 5" v-if="$root.invitesEnabled">
              <router-link
                :to="{ name: 'invites' }"
                data-cy="menu-invites-mobile"
              >
                <i class="mdi mdi-account-multiple-plus"></i>
                <span v-text="$t('components.menuOptions.invites')"></span>
              </router-link>
            </li>
            <li class="filler"></li>
          </ul>
        </div>
      </transition>
    </teleport>
  </nav>
</template>

<script>
import settings from '@/../settings.json';
import { mapGetters, mapMutations } from 'vuex';
import { APP_GETTERS, APP_MUTATIONS } from '@/store/modules/app';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import BadgeVue from '@common/Components/Badge.vue';

export default {
  components: { BadgeVue },
  data() {
    return {
      settings
    };
  },
  methods: {
    ...mapMutations('app', {
      $setPageSettings: APP_MUTATIONS.SET_PAGE_SETTING
    }),
    toggleLargeMenu() {
      this.$setPageSettings({
        option: 'show_large_menu',
        value: !this.$showLargeMenu
      });
    }
  },
  computed: {
    ...mapGetters('currentuser', {
      $promrequests: CURRENT_USER_GETTERS.INCOMPLETE_PROMREQUESTS
    }),
    ...mapGetters('app', {
      $showLargeMenu: APP_GETTERS.SHOW_LARGE_MENU
    }),
    totalNumberNotices() {
      return this.$promrequests?.length;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

$color: rgba(18, 32, 51, 0.5);
$activeColor: rgba(18, 32, 51, 1);

.main-menu {
  height: $main-menu-height;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  background-color: #fff;
  line-height: normal;
  color: $color;
  z-index: 99999;

  ul {
    @media only screen and (max-width: $breakpoint-phone) {
      justify-content: space-evenly;
    }
    justify-content: center;
    height: $main-menu-height;
    display: flex;
    align-content: center;
    li {
      a {
        @media only screen and (max-width: $breakpoint-desktop) {
          padding: 0 1em;
        }
        padding: 0;
        color: $color;
        display: grid;
        grid-template-rows: 1fr 0.5fr;
        justify-items: center;
        &.router-link-exact-active,
        &.active {
          color: $activeColor;
          font-weight: bold;
        }
        &:hover {
          color: lighten($activeColor, 20%);
          background-color: transparent;
        }
      }

      i {
        display: inline-block;
        line-height: normal;
        font-size: 30px;
        align-self: flex-end;
        &:before {
          align-self: flex-start;
        }
      }
      span {
        font-family: Dosis;
        align-self: flex-start;
        font-size: 9px;
        margin-top: -23px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-desktop) {
    top: $header-height-desktop;
    left: 0;
    bottom: 0;
    right: auto;
    min-height: calc(100vh - #{$header-height-desktop});
    width: $side-menu-width;
    padding-top: 49px;
    ul {
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;

      li {
        margin-left: 10px;
        margin-bottom: 20px;
        a {
          grid-template-columns: 0.5fr 1fr;
          grid-template-rows: auto;
          justify-items: flex-start;
          align-items: center;
          text-transform: uppercase;
          font-family: Dosis;
        }

        i {
          font-size: 25px;
          height: auto;
          align-self: flex-end;
          justify-self: center;
          flex: 0.5;
          &:before {
            align-self: center;
          }
        }
        span {
          align-self: center;
          font-size: 15px;
          margin-top: 0;
          letter-spacing: normal;
        }
      }
    }
  }
}
.more-menu {
  position: fixed;
  min-height: calc(100vh - #{$main-menu-height} - #{$header-height});
  width: 100vw;
  background: $background-color;
  z-index: 9999;
  top: $header-height;
  display: grid;
  align-content: center;
  padding: 15px 20px 20px 20px;

  img {
    max-width: 110px;
    justify-self: center;
  }

  ul {
    display: flex;
    justify-content: center;
    flex-direction: row;
    max-width: 100vw;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100vh - #{$main-menu-height} - #{$header-height});
    margin: 0;

    li {
      min-width: 150px;
      height: 125px;
      max-width: 200px;
      border-radius: 15px;
      box-shadow: 0 20px 25px 0 rgba(73, 73, 73, 0.1);
      background-color: #ffffff;
      margin-bottom: 5px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      a {
        display: grid;
        grid-template-rows: 1fr 0.5fr;
        flex: 1;
        justify-items: center;
        align-items: center;
      }
      &.filler {
        background-color: transparent;
        box-shadow: none;
      }
      @media only screen and (max-width: $breakpoint-phone-small) {
        min-width: 135px;
        max-width: 135px;
        height: 105px;
      }
      span {
        text-transform: uppercase;
        align-self: flex-start;
        font-family: Dosis;
        font-size: 15px;
      }
      i {
        align-self: flex-end;
        display: inline;
        font-size: 60px;
        line-height: 60px;
        color: $symptoms-blue;
        @media only screen and (max-width: $breakpoint-phone-small) {
          font-size: 50px;
        }
      }
    }
  }
}
</style>
