import PromReportModel from '@common/Models/PromReportModel';
import BaseService from '../BaseService';
import {
  NewPromReportRequest,
  UpdatePromReportRequest
} from './mappers/PromReportRequests';
import {
  MappedExportsResponse,
  PromReportResponse,
  PromReportsResponse
} from './mappers/PromReportResponses';

class PromReportService extends BaseService {
  /**
   * Get promreports from last month -- backend
   * @returns {Promise<PromReportModel[]>}
   * @memberof PromReportService
   */
  getMyPromReports() {
    return this.backend('my/promreports').then(response =>
      PromReportsResponse(response.data)
    );
  }

  /**
   * Get all promreports -- backend
   * @returns {Promise<PromReportModel[]>}
   * @memberof PromReportService
   */
  getAllMyPromReports() {
    return this.backend('my/promreports/all').then(response =>
      PromReportsResponse(response.data)
    );
  }

  /**
   * Fetch reports on a specific Prom -- backend
   * @param {string} PromId
   * @returns {Promise<PromReportModel[]>}
   * @memberof PromReportService
   */
  getMyPromReportsByProm(PromId) {
    return this.backend(`my/promreports/prom/${PromId}`).then(response =>
      PromReportsResponse(response.data)
    );
  }

  /**
   * Fetch all reports on a specific Prom -- backend
   * @param {string} PromId
   * @returns {Promise<PromReportModel[]>}
   * @memberof PromReportService
   */
  findAllMyPromReportByProm(PromId) {
    return this.backend(`my/promreports/prom/${PromId}/all`).then(response =>
      PromReportsResponse(response.data)
    );
  }

  /**
   * Fetch prom report -- backend
   * @param {string} id PromReport id
   * @returns {Promise<PromReportModel>}
   * @memberof PromReportService
   */
  findMyPromReport(id) {
    return this.backend(`my/promreports/${id}`).then(response =>
      PromReportResponse(response.data)
    );
  }

  /**
   * Save promreport -- backend
   * @param {PromReportModel} promreport
   * @returns {Promise<PromReportModel>}
   * @memberof PromReportService
   */
  addMyPromReport(promreport) {
    return this.backend(
      'post',
      'my/promreports',
      NewPromReportRequest(promreport)
    ).then(response => PromReportResponse(response.data));
  }

  /**
   * Save promreport requesr -- backend
   * @param {String} requestid
   * @param {PromReportModel} promreport
   * @returns {Promise<PromReportModel>}
   * @memberof PromReportService
   */
  fillMyPromRequest(requestId, promreport) {
    return this.backend(
      'post',
      `promrequests/fill/${requestId}`,
      NewPromReportRequest(promreport)
    ).then(response => PromReportResponse(response.data));
  }

  /**
   * Update promreport -- backend
   * @param {PromReportModel} promreport
   * @returns {Promise<PromReportModel>}
   * @memberof PromReportService
   */
  updateMyPromReport(promreport) {
    return this.backend(
      'put',
      `my/promreports/${promreport.Id}`,
      UpdatePromReportRequest(promreport)
    ).then(response => PromReportResponse(response.data));
  }

  /**
   * Delete a PromReport -- backend
   * @param {string} guid PromReport id
   * @returns {Promise}
   * @memberof PromReportService
   */
  deleteMyPromReport(guid) {
    return this.backend('post', `my/promreports/${guid}/delete`);
  }

  /**
   * Purge deleted PromReports -- backend
   * @param {string} id PromReport id
   * @returns {Promise}
   * @memberof PromReportService
   */
  purgeMyPromReport(id) {
    return this.backend('delete', `my/promreports/${id}`);
  }

  /**
   * Purge deleted PromReports -- backend
   * @returns {Promise}
   * @memberof PromReportService
   */
  purgePromReports() {
    return this.backend('my/promreports/purge');
  }

  /**
   * Restore deleted PromReport -- backend
   * @param {string} guid PromReport id
   * @returns {Promise}
   * @memberof PromReportService
   */
  restoreMyPromReport(guid) {
    return this.backend('post', `my/promreports/${guid}/restore`);
  }

  /**
   * Fetch prom report -- backend
   * @param {string} id PromReport id
   * @returns {Promise<PromReportModel>}
   * @memberof PromReportService
   */
  findPromReport(id) {
    return this.backend(`promreports/${id}`).then(report =>
      PromReportResponse(report.data)
    );
  }

  /**
   * Fetch prom request answers -- backend
   * @param {string} id request id
   * @returns {Promise<PromReportModel>}
   * @memberof PromReportService
   */
  findPromRequestAnswers(id) {
    return this.backend(`promrequests/${id}/answers`).then(report =>
      PromReportResponse(report.data)
    );
  }

  /**
   * Fetch prom reports by group -- backend
   * @param {string} orgId organization id
   * @param {string} grpId group id
   * @returns {Promise<PromReportModel[]>}
   * @memberof PromReportService
   */
  getPromReportsByGroup(orgId, grpId) {
    return this.backend(
      `organizations/${orgId}/groups/${grpId}/promreports`
    ).then(response => PromReportsResponse(response.data));
  }

  /**
   * Fetch prom reports export data by group -- backend
   * @returns {Promise<PromReportModel[]>}
   * @memberof PromReportService
   */
  getPromReportsExportDataByGroup(requiredContracts) {
    return this.backend(
      'post',
      'admin/promreports/export',
      requiredContracts
    ).then(response => response.data);
  }

  /**
   * Fetch mapped prom reports export data by group -- backend
   * @returns {Promise<MappedExportModel[]>}
   * @memberof PromReportService
   */
  getPromReportsMappedExportDataByGroup(mappedExportRequest) {
    return this.backend(
      'post',
      'admin/promreports/mapped-export',
      mappedExportRequest
    ).then(response => MappedExportsResponse(response.data));
  }

  /**
   * Export prom request -- backend
   * @param {string} requestId group id
   * @returns {Promise<PromReportModel[]>}
   * @memberof PromReportService
   */
  getPromRequestExportData(requestId) {
    return this.backend(`promrequests/${requestId}/export`).then(response =>
      PromReportsResponse(response.data)
    );
  }
}

export default new PromReportService();
