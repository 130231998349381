// @ts-check
import { required, minValue, maxValue } from '@vuelidate/validators';

export class PromGroupModel {
  constructor({
    OrganizationId,
    GroupId,
    PromId,
    ForceFill = false,
    FillCount,
    IntervalDays
  }) {
    this._modelName = 'PromGroup';
    this.OrganizationId = OrganizationId;
    this.GroupId = GroupId;
    this.PromId = PromId;
    this.ForceFill = ForceFill;
    this.FillCount = FillCount;
    this.IntervalDays = IntervalDays;
  }

  static get validations() {
    return {
      PromId: {
        required
      },
      ForceFill: {},
      FillCount: {
        minValue: minValue(-2147483648),
        maxValue: maxValue(2147483647)
      },
      IntervalDays: {
        minValue: minValue(-2147483648),
        maxValue: maxValue(2147483647)
      }
    };
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default PromGroupModel;
