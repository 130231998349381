// @ts-check
import { isFromRemote } from '@common/Helpers/api';
import { sortByField } from '@common/Helpers/sorters';
import { utcToLocal } from '@common/Helpers/time';
import ContractSectionModel from '@common/Models/ContractSectionModel';
import moment from 'moment';
import { maxLength, required } from '@vuelidate/validators';

export class ContractModel {
  constructor({
    Id,
    ContractId,
    Active,
    ContractVersion,
    ConsentDate,
    DataProcessing = false,
    Description,
    Descriptor,
    GroupId,
    Language,
    Notes = false,
    OrganizationId,
    PromReports = false,
    Index,
    Reference,
    ReferenceDescription,
    Repeatable = false,
    Required = false,
    RequiredRead = false,
    RequireReference = false,
    Sections = [],
    StatusReports = false,
    SymptomReports = false,
    TermsOfService = false,
    Title,
    Underage = false,
    ValidFromDate,
    Version = 0,
    WithdrawDate,
    _saved = false
  }) {
    this.Id = Id;
    this.ContractId = ContractId;
    this.Active = Active;
    this.ContractVersion = ContractVersion;
    this.ConsentDate = ConsentDate ? utcToLocal(ConsentDate) : null;
    this.ValidFromDate = ValidFromDate ? utcToLocal(ValidFromDate) : moment();
    this.DataProcessing = DataProcessing;
    this.Description = Description;
    this.Descriptor = Descriptor;
    this.GroupId = GroupId;
    this.Language = Language;
    this.Notes = Notes;
    this.OrganizationId = OrganizationId;
    this.PromReports = PromReports;
    this.Index = Index;
    this.Reference = Reference;
    this.ReferenceDescription = ReferenceDescription;
    this.Repeatable = Repeatable;
    this.Required = Required;
    this.RequiredRead = RequiredRead;
    this.RequireReference = RequireReference;
    this.Sections = Sections.map(s => new ContractSectionModel(s)).sort(
      sortByField('Index')
    );
    this.StatusReports = StatusReports;
    this.SymptomReports = SymptomReports;
    this.TermsOfService = TermsOfService;
    this.Title = Title;
    this.Underage = Underage;
    this.Version = Version;
    this.WithdrawDate = WithdrawDate ? utcToLocal(WithdrawDate) : null;

    this._accepted = false;
    this._saved = _saved || isFromRemote(this);
  }

  get isConsented() {
    return this.ConsentDate && !this.WithdrawDate;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Title: {
        required
      },
      Index: {},
      ContractVersion: {
        required
      },
      Version: {
        required
      },
      Descriptor: {
        required,
        maxLength: maxLength(16)
      },
      Description: {
        required
      },
      Language: {
        required
      },
      ReferenceRegex: {},
      ReferenceDescription: {},
      Sections: {}
    };
  }
}

export default ContractModel;
