import {
  CURRENT_USER_GETTERS,
  CURRENT_USER_MUTATIONS
} from '@/store/modules/currentuser';
import { PROM_GETTERS } from '@/store/modules/proms';
import { STATUSREPORT_MUTATIONS } from '@/store/modules/statusreports';
import { DateTimeStringForFile } from '@common/Filters/Date';
import { sortByCreatedDate } from '@common/Helpers/sorters';
import storage from '@common/Helpers/storage';
import NoteModel from '@common/Models/NoteModel';
import PromReportModel from '@common/Models/PromReportModel';
import PromReportService from '@common/Services/PromReports/PromReportService';
import SymptomReportSymptomService from '@common/Services/SymptomReportSymptoms/SymptomReportSymptomService';
import UserService from '@common/Services/User/UserService';
import { PromReportResponse } from '@common/Services/PromReports/mappers/PromReportResponses';
import jszip from 'jszip';
import moment from 'moment';
import Papa from 'papaparse';
import {
  PROMREPORT_ACTIONS,
  PROMREPORT_GETTERS,
  PROMREPORT_MUTATIONS
} from './definitions';

class State {
  constructor() {
    /** @type {PromReportModel[]} array of PromReports */
    this.list = [];
    /** @type {SymptomReportModel} */
    this.unsavedReport = null;
  }
}

const store = {
  namespaced: true,
  state: new State(),

  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /**
   * Set reports
   * @param {PromReportModel[]} reports
   */
  [PROMREPORT_MUTATIONS.SET_REPORTS](state, reports) {
    state.list = reports;
  },

  /**
   * Set unsaved PROM report
   * @param {PromReportModel} report
   */
  [PROMREPORT_MUTATIONS.SET_UNSAVED_REPORT](state, report) {
    state.unsavedReport = report;
  },

  /**
   * Add report
   * @param {PromReportModel} report
   */
  [PROMREPORT_MUTATIONS.ADD_NEW_REPORT](state, report) {
    const exists = state.list.find(r => r.Id === report.Id);

    if (!exists) {
      state.list.push(report);
    } else {
      if (report.Answers.length < exists.Answers.length) {
        delete report.Answers;
      }
      state.list[exists] = report;
    }
  },

  /**
   * Remove report
   * @param {PromReportModel} report
   */
  [PROMREPORT_MUTATIONS.REMOVE_REPORT](state, report) {
    const index = state.list.findIndex(r => r.Id === report.Id);
    state.reports = state.list.splice(index, 1);
    storage.remove(`promreport/${report.Id}`);
  },

  /**
   * Save report successful
   * @param {Object} payload
   * @param {PromReportModel} payload.oldReport
   * @param {PromReportModel} payload.report
   */
  [PROMREPORT_MUTATIONS.SAVE_REPORT_SUCCESS](state, payload) {
    storage.remove(`promreport/${payload.oldReport.Id}`);

    const report = payload.report;

    const exists = state.list.find(r => r.Id === report.Id);

    if (!exists) {
      state.list.push(report);
    } else {
      Object.assign(exists, report);
    }
  },

  /**
   * Save report failed
   * @param {Object} payload
   * @param {PromReportModel} payload.oldReport
   */
  [PROMREPORT_MUTATIONS.SAVE_REPORT_FAILURE](state, payload) {
    storage.setEncrypted(
      `promreport/${payload.oldReport.Id}`,
      payload.oldReport
    );
  },

  /**
   * Mark report as saved
   * @param {PromReportModel} report
   */
  [PROMREPORT_MUTATIONS.SET_REPORT_CLEAN](state, report) {
    report.saved = true;
    report.Answers.forEach(answer => (answer.saved = true));
  },

  /**
   * Attach note to PromReport
   * @param {Object} payload
   * @param {PromReportModel} payload.report
   * @param {NoteModel} payload.note
   */
  [PROMREPORT_MUTATIONS.ATTACH_NOTE](state, payload) {
    payload.report.Note = payload.note;
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  [PROMREPORT_ACTIONS.$PREINIT]({ commit, dispatch }, reports) {
    let unsavedPromReports = storage.find('promreport', true);

    if (unsavedPromReports) {
      unsavedPromReports = unsavedPromReports.map(report => {
        report = PromReportResponse(report);
        report.local = true;
        return report;
      });

      commit(PROMREPORT_MUTATIONS.SET_REPORTS, unsavedPromReports);
    }

    dispatch(PROMREPORT_ACTIONS.GET_MY_PROMREPORTS, reports);
  },

  [PROMREPORT_ACTIONS.SET_UNSAVED_REPORT]({ commit }, report) {
    storage.setEncrypted(`promreport/unsaved`, report);
    commit(PROMREPORT_MUTATIONS.SET_UNSAVED_REPORT, report);
  },

  [PROMREPORT_ACTIONS.GET_UNSAVED_REPORT]({ commit }) {
    const report = storage.getEncrypted('promreport/unsaved');
    let unsavedReport = null;

    if (report) {
      unsavedReport = new PromReportModel(report);
    }

    commit(PROMREPORT_MUTATIONS.SET_UNSAVED_REPORT, unsavedReport);
  },

  /**
   * Remove unsaved PROM report
   */
  [PROMREPORT_ACTIONS.REMOVE_UNSAVED_REPORT]({ commit }) {
    storage.remove(`promreport/unsaved`);
    commit(PROMREPORT_MUTATIONS.SET_UNSAVED_REPORT, null);
  },

  [PROMREPORT_ACTIONS.GET_MY_PROMREPORT]({ state, commit }, ReportId) {
    const loaded = state.list.find(report => report.Id === ReportId);
    if (loaded && !loaded.needFullReport) {
      return Promise.resolve(loaded);
    }

    return PromReportService.findMyPromReport(ReportId).then(report => {
      commit(PROMREPORT_MUTATIONS.ADD_NEW_REPORT, report);
      return report;
    });
  },

  [PROMREPORT_ACTIONS.GET_MY_PROMREPORTS]({ commit }, reports) {
    if (reports) {
      return commit(PROMREPORT_MUTATIONS.SET_REPORTS, reports);
    }
    return PromReportService.getMyPromReports().then(reports =>
      commit(PROMREPORT_MUTATIONS.SET_REPORTS, reports)
    );
  },

  [PROMREPORT_ACTIONS.GET_MY_LATEST_PROMREPORT_BY_PROM](
    { state, commit },
    PromId
  ) {
    let loaded = state.list.filter(report => report.PromId === PromId);
    loaded = [...sortByCreatedDate(loaded)].reverse()[0];
    if (loaded && !loaded.needFullReport) {
      return Promise.resolve(loaded);
    }

    if (!loaded) {
      return;
    }

    return PromReportService.findMyPromReport(loaded.Id).then(report => {
      commit(PROMREPORT_MUTATIONS.ADD_NEW_REPORT, report);
      return report;
    });
  },

  [PROMREPORT_ACTIONS.ADD_OR_UPDATE_REPORT](
    { commit, dispatch, rootGetters },
    oldReport
  ) {
    const isPromRequest = rootGetters[
      `currentuser/${CURRENT_USER_GETTERS.IS_PROMREQUEST}`
    ](oldReport.PromId);

    if (isPromRequest && oldReport.isCompleted) {
      commit(
        `currentuser/${CURRENT_USER_MUTATIONS.REMOVE_PROMREQUEST}`,
        oldReport.PromId,
        {
          root: true
        }
      );
    }

    if (oldReport.isFromRemote) {
      return PromReportService.updateMyPromReport(oldReport)
        .then(newreport => {
          commit(PROMREPORT_MUTATIONS.SAVE_REPORT_SUCCESS, {
            report: newreport,
            oldReport
          });
          commit(PROMREPORT_MUTATIONS.SET_REPORT_CLEAN, newreport);
          dispatch(
            PROMREPORT_ACTIONS.REPLACE_OLD_REPORTS_WITH_REPORT,
            newreport
          );
          commit(
            `statusreports/${STATUSREPORT_MUTATIONS.COMPLETE_FOLLOWUP}`,
            oldReport.PromId,
            { root: true }
          );
          return newreport;
        })
        .catch(error => {
          commit(PROMREPORT_MUTATIONS.SAVE_REPORT_FAILURE, {
            oldReport,
            error: error.toString()
          });
          throw error;
        });
    }

    return PromReportService.addMyPromReport(oldReport)
      .then(newReport => {
        commit(PROMREPORT_MUTATIONS.SAVE_REPORT_SUCCESS, {
          report: newReport,
          oldReport
        });
        commit(PROMREPORT_MUTATIONS.SET_REPORT_CLEAN, newReport);
        dispatch(PROMREPORT_ACTIONS.REPLACE_OLD_REPORTS_WITH_REPORT, newReport);
        commit(
          `statusreports/${STATUSREPORT_MUTATIONS.COMPLETE_FOLLOWUP}`,
          newReport.PromId,
          { root: true }
        );
        return newReport;
      })
      .catch(error => {
        commit(PROMREPORT_MUTATIONS.SAVE_REPORT_FAILURE, {
          oldReport,
          error: error.toString()
        });
        throw error;
      });
  },

  [PROMREPORT_ACTIONS.REPLACE_OLD_REPORTS_WITH_REPORT](
    { commit, getters },
    report
  ) {
    const reports = getters[PROMREPORT_GETTERS.FIND_PROMREPORTS_BY_PROMID](
      report.PromId
    ).filter(r => r.Id !== report.Id && !r.isCompleted);
    for (let i = 0, n = reports.length; i < n; i++) {
      commit(PROMREPORT_MUTATIONS.REMOVE_REPORT, reports[i]);
    }
  },

  [PROMREPORT_ACTIONS.ATTACH_NOTE]({ commit, state }, details) {
    const report = find(state.reports, { Id: details.reportid });

    commit(PROMREPORT_MUTATIONS.ATTACH_NOTE, {
      note: details.note,
      report
    });

    if (!isRemoteId(details.reportid)) {
      commit(types.NOTE_SAVE_SUCCESS);
      return false;
    }

    return PromReportService.updateMyPromReport(details.reportid, report)
      .then(response => {
        // commit(types.SET_REPORT_CLEAN, Report)
        commit(types.SAVE_REPORT_SUCCESS, { report, response });

        return response;
      })
      .catch(() => commit(types.NOTE_SAVE_FAILURE));
  },

  [PROMREPORT_ACTIONS.REMOVE_REPORT]({ commit }, report) {
    if (!report.isFromRemote) {
      commit(PROMREPORT_MUTATIONS.REMOVE_REPORT, report);
      return;
    }

    return PromReportService.deleteMyPromReport(report.Id).then(response =>
      commit(PROMREPORT_MUTATIONS.REMOVE_REPORT, report)
    );
  },

  async [PROMREPORT_ACTIONS.EXPORT_MAPPED_DATA](context, mappedExportRequest) {
    var csvDataArray = [];

    await PromReportService.getPromReportsMappedExportDataByGroup(
      mappedExportRequest
    ).then(mappedExports => {
      if (mappedExports.length === 0) {
        alert('Ingen data hittad för givet intervall.');
        return;
      }

      mappedExports.forEach(ms => {
        var data = {};

        ms.Items.forEach(i => {
          data[i.Tag] = i.Value;
        });

        csvDataArray.push(data);
      });
    });

    const currentDateTime = moment().format(DateTimeStringForFile);
    const label = `mapped_export_${currentDateTime}`;

    const bom = '\ufeff'; // BOM character to get correct åäö encoding
    const csvData = Papa.unparse(csvDataArray);
    const csv = `${bom}${csvData}`;

    const blob = new Blob([csv], {
      type: 'data:text/csv;charset=utf-8'
    });
    saveAs(blob, `${label}.csv`);
  },

  [PROMREPORT_ACTIONS.EXPORT_PROMREPORTS_BY_GROUP_JSON](
    context,
    requiredContracts
  ) {
    return PromReportService.getPromReportsExportDataByGroup(
      requiredContracts
    ).then(proms => {
      if (Object.keys(proms).length === 0) {
        alert('Ingen data hittad för givet intervall.');
        return;
      }

      const currentDateTime = moment().format(DateTimeStringForFile);
      const label = `prom_results_${currentDateTime}`;
      const blob = new Blob([JSON.stringify(proms)], {
        type: 'application/json; charset=utf-8'
      });
      saveAs(blob, `${label}.json`);
    });
  },

  [PROMREPORT_ACTIONS.EXPORT_PROMREPORTS_BY_GROUP_CSV](
    context,
    requiredContracts
  ) {
    return PromReportService.getPromReportsExportDataByGroup(
      requiredContracts
    ).then(proms => {
      if (Object.keys(proms).length === 0) {
        alert('Ingen data hittad för givet intervall.');
        return;
      }

      const promsData = Object.keys(proms).map(promId => {
        const reports = proms[promId].map(report => {
          const [CreatedDate, CreatedTime] = report.CreatedDate.split('T');
          const [ModifiedDate, ModifiedTime] = report.ModifiedDate.split('T');

          const promData = {
            Identifier: report.hasOwnProperty('Participant')
              ? report.Participant.Identifiers[2].Value.replace('-', '')
              : '',
            Language: report.Language,
            Score: report.Score,
            NormalizedScore: report.NormalizedScore,
            CreatedDate: CreatedDate,
            CreatedTime: CreatedTime,
            ModifiedDate: ModifiedDate,
            ModifiedTime: ModifiedTime
          };

          // Add each Question as key & value too
          report.Answers.forEach((answer, index) => {
            const questionHeader = `q${index + 1}-${answer.Header}`;
            promData[questionHeader] = answer.Answer.join(',');
          });

          return promData;
        });

        return { promId, reports };
      });

      const zip = new jszip();
      promsData.forEach(promData => {
        const bom = '\ufeff'; // BOM character to get correct åäö encoding
        const csvData = Papa.unparse(promData.reports);
        const csv = `${bom}${csvData}`;

        zip.file(promData.promId + '.csv', csv);
      });

      const currentDateTime = moment().format(DateTimeStringForFile);
      const label = `prom_results_${currentDateTime}`;
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, `${label}.zip`);
      });

      return proms;
    });
  },

  [PROMREPORT_ACTIONS.EXPORT_SYMPTOMS_BY_GROUP_CSV](
    context,
    requiredContracts
  ) {
    return SymptomReportSymptomService.getSymptomReportSymptomsExport(
      requiredContracts
    ).then(symptomReportSymptoms => {
      if (symptomReportSymptoms.length === 0) {
        alert('Ingen data hittad för givet intervall.');
        return;
      }

      const csvDataArray = [];
      // Remap for csv
      symptomReportSymptoms.map(row => {
        let personnummer;
        // Find personnummer value
        for (let i = 0; i < row.Participant.Identifiers.length; i++) {
          if (row.Participant.Identifiers[i].Description == 'Personnummer') {
            personnummer = row.Participant.Identifiers[i].Value;
            personnummer = personnummer.replace('-', '');
          }
        }

        const csvData = {
          Personnummer: personnummer,
          ReportedTime: row.CreatedDate,
          Symptom: row.Symptom.Name,
          Intensity: row.Intensity,
          Note: row.Note ? row.Note : '',
          SymptomCoordinates: `${JSON.stringify(row.SymptomPoints)}`,
          PatientModelChosenSide: row.PatientModelChosenSide,
          BrowserAgent: row.browserAgent
        };
        csvDataArray.push(csvData);
      });

      const currentDateTime = moment().format(DateTimeStringForFile);
      const label = `symptoms_export_${currentDateTime}`;

      const bom = '\ufeff'; // BOM character to get correct åäö encoding
      const csvData = Papa.unparse(csvDataArray);
      const csv = `${bom}${csvData}`;

      const blob = new Blob([csv], {
        type: 'data:text/csv;charset=utf-8'
      });
      saveAs(blob, `${label}.csv`);
    });
  },

  [PROMREPORT_ACTIONS.EXPORT_SUBJECT_INFORMATION_BY_GROUP_CSV](
    context,
    requiredContracts
  ) {
    return UserService.getSubjectInformationExport(requiredContracts).then(
      participants => {
        if (participants.length === 0) {
          alert('Ingen data hittad för givet intervall.');
          return;
        }
        const csvDataArray = [];
        // Remap for csv
        participants.map(row => {
          let personnummer;
          // Find personnummer value
          for (let i = 0; i < row.Identifiers.length; i++) {
            if (row.Identifiers[i].Description === 'Personnummer') {
              personnummer = row.Identifiers[i].Value.replace('-', '');
            }
          }

          const csvData = {
            Personnummer: personnummer,
            Sex: row.Gender,
            Age: row.Age,
            Manikin: 'Manikin'
          };
          csvDataArray.push(csvData);
        });

        const currentDateTime = moment().format(DateTimeStringForFile);
        const label = `subject_information_${currentDateTime}`;

        const bom = '\ufeff'; // BOM character to get correct åäö encoding
        const csvData = Papa.unparse(csvDataArray);
        const csv = `${bom}${csvData}`;

        const blob = new Blob([csv], {
          type: 'data:text/csv;charset=utf-8'
        });
        saveAs(blob, `${label}.csv`);
      }
    );
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [PROMREPORT_GETTERS.UNSAVED_REPORT]: state => {
    return state.unsavedReport;
  },
  [PROMREPORT_GETTERS.FIND_PROMREPORT]: state => reportid => {
    const report = state.list.find(prom => prom.Id === reportid);
    return report;
  },
  [PROMREPORT_GETTERS.PROM_REPORTS](state) {
    return state.list;
  },
  [PROMREPORT_GETTERS.PROM_REPORTS_GROUPED_BY_PROM](state) {
    const reportsByProm = {};
    let empty = true;

    for (let i = 0; i < state.list.length; i++) {
      if (reportsByProm[state.list[i].PromId] === undefined) {
        reportsByProm[state.list[i].PromId] = [];
      }
      reportsByProm[state.list[i].PromId].push(state.list[i]);
      empty = false;
    }
    return !empty ? reportsByProm : null;
  },
  [PROMREPORT_GETTERS.FIND_PROMREPORTS_BY_PROMID]:
    (state, getters) => promid => {
      return state.list.filter(report => report.PromId === promid);
    },
  [PROMREPORT_GETTERS.FIND_LATEST_PROMREPORT_BY_PROMID]:
    (state, getters) => promid => {
      const list = state.list.filter(report => report.PromId === promid);
      const latestPromReport = [...sortByCreatedDate(list)].reverse()[0];
      return latestPromReport;
    },
  [PROMREPORT_GETTERS.PROM_GRAPH_DATA]: (state, getters) => promid => {
    return getters[PROMREPORT_GETTERS.FIND_PROMREPORTS_BY_PROMID](promid)
      .filter(report => report.isCompleted)
      .map(report => {
        return {
          date: report.ModifiedDate.toDate(),
          moment: report.ModifiedDate,
          value: +report.NormalizedScore
        };
      });
  },
  [PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS](
    state,
    getters,
    rootState,
    rootGetters
  ) {
    let list = state.list
      .filter(r => !r.isCompleted)
      .filter(r => {
        const prom = rootGetters[`proms/${PROM_GETTERS.FIND_PROM}`](r.PromId);
        return (
          !!prom &&
          prom.Language ===
          rootGetters[`currentuser/${CURRENT_USER_GETTERS.ACTIVE_LANGUAGE}`]
        );
      });
    list = [...sortByCreatedDate(list)].reverse();
    return list;
  },
  [PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS_BY_PROMID]:
    (state, getters) => promid => {
      return getters[PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS].filter(
        report => report.PromId === promid
      )[0];
    }
};

export {
  PROMREPORT_ACTIONS,
  PROMREPORT_GETTERS,
  PROMREPORT_MUTATIONS
} from './definitions';

export default store;
