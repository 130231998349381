<template>
  <div v-if="report">
    <div class="container">
      <h4 class="page-header">
        {{
          isComingFromProm
            ? $t('views.symptomSelect.questionsAboutSymptoms')
            : $t('generic.nameTheSymptom')
        }}
      </h4>
      <div class="grey-text">
        <span v-if="isComingFromProm">
          {{ $t('views.symptomSelect.doYouHaveAnySymptoms') }}
          {{ $t('views.symptomSelect.selectFromListOrAddOwn') }}
        </span>
      </div>

      <flex-grid
        :force-row="true"
        :full-width="true"
        justify="flex-start"
        align-items="center"
        :padding="0"
      >
        <div>
          <s-text-input
            v-model="symptomDefinition.Name"
            @keyup.enter="saveSymptomDefinition()"
            type="text"
            data-cy="symptomselect-form-name"
            :rounded="true"
            :disabled="isLoading"
            autofocus
          ></s-text-input>
        </div>
        <div style="min-width: 120px">
          <s-button
            @click="saveSymptomDefinition()"
            :primary="true"
            class="ml10"
          >
            {{ $t('generic.add') }}
          </s-button>
        </div>
      </flex-grid>
      <flex-grid
        :force-row="true"
        :full-width="true"
        justify="flex-start"
        :padding="0"
        align-items="top"
      >
        <errors :field="v$.symptomDefinition.Name" />
      </flex-grid>

      <div style="padding: 5px 10px">
        <div class="symptom-select">
          <ul
            class="symptom-select__symptom-definitions"
            v-if="filteredSymptomDefinitions.length > 0"
          >
            <li
              v-for="symptomDefinition in filteredSymptomDefinitions"
              :key="symptomDefinition.Id"
              :class="{
                selected: isSelected(symptomDefinition),
                pointer: !isSelected(symptomDefinition)
              }"
              @click="addSymptom(symptomDefinition)"
            >
              <span class="responsive">
                <span>{{ symptomDefinition.Name }}</span>
                <span v-if="symptomDefinition.isCreatedByPatient">
                  {{ `(${$t('generic.own')})` }}
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="isComingFromProm" style="text-align: center" class="mt20">
        <s-button
          @click="iHaveNoSymptoms()"
          :loading="iHaveNoSymptomsLoading"
          :primary="true"
          class="mb10 mt20"
        >
          {{ $t('views.symptomSelect.iHaveNoSymptoms') }}
        </s-button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { APP_MUTATIONS } from '@/store/modules/app';
import { SYMPTOM_GETTERS } from '@/store/modules/symptoms';
import {
  SYMPTOMREPORT_ACTIONS,
  SYMPTOMREPORT_GETTERS,
  SYMPTOMREPORT_MUTATIONS
} from '@/store/modules/symptomreports';
import { CURRENT_USER_GETTERS } from '@/store/modules/currentuser';
import { randomString } from '@common/Helpers/strings';
import {
  SYMPTOM_DEFINITION_ACTIONS,
  SYMPTOM_DEFINITION_GETTERS
} from '@/store/modules/symptomdefinitions';
import SymptomDefinitionModel from '@common/Models/SymptomDefinitionModel';
import SymptomModel from '@common/Models/Symptom/SymptomModel';
import SymptomReportModel from '@common/Models/SymptomReport/SymptomReportModel';
import SymptomReportSymptomModel from '@common/Models/SymptomReport/SymptomReportSymptomModel';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      symptomDefinition: new SymptomDefinitionModel({}),
      report: null,
      isLoading: false,
      iHaveNoSymptomsLoading: false
    };
  },
  mounted() {
    this.$getMySymptomDefinitions();
    if (this.$unsavedSymptomsReport) {
      this.loadTempReport();
    } else {
      this.createSymptomReport();
    }

    this.setLayoutSettings();
  },
  watch: {},
  created() {},
  validations: {
    symptomDefinition: SymptomDefinitionModel.validations
  },
  methods: {
    ...mapActions('symptomdefinitions', {
      $createSymptomDefinition:
        SYMPTOM_DEFINITION_ACTIONS.CREATE_SYMPTOM_DEFINITION,
      $getMySymptomDefinitions:
        SYMPTOM_DEFINITION_ACTIONS.GET_MY_SYMPTOM_DEFINITIONS,
      $removeSymptomDefinition:
        SYMPTOM_DEFINITION_ACTIONS.REMOVE_SYMPTOM_DEFINITION
    }),
    ...mapActions('symptomreports', {
      $addOrUpdateSymptomReport: SYMPTOMREPORT_ACTIONS.ADD_OR_UPDATE_REPORT
    }),
    ...mapMutations('symptomreports', {
      $saveTempSymptomReport: SYMPTOMREPORT_MUTATIONS.SET_UNSAVED_REPORT
    }),
    ...mapMutations('app', {
      $setPageSettings: APP_MUTATIONS.SET_PAGE_SETTING
    }),
    loadTempReport() {
      this.report = new SymptomReportModel(
        this.$unsavedSymptomsReport?.__copy()
      );
    },
    createSymptomReport() {
      this.report = new SymptomReportModel({
        Id: randomString(),
        Model: this.$getActiveSymptomModel.Id
      });

      this.$currentSymptoms.forEach(s => {
        const srs = new SymptomReportSymptomModel(s.lastReport.__copy());
        this.report.Symptoms.push(srs);
      });
    },
    isSelected(symptomDefinition) {
      if (!this.report) {
        return false;
      }

      return !!this.report.Symptoms.find(
        srs =>
          srs.Symptom.Name.toLowerCase() ===
          symptomDefinition.Name?.toLowerCase()
      );
    },
    addSymptom(symptomDefinition, custom = false) {
      if (this.isSelected(symptomDefinition)) {
        const message = `${this.$t('views.symptomSelect.alreadyAdded')} ${
          custom
            ? this.$t('views.symptomSelect.alreadyAddedCustomAddition')
            : ''
        }`;
        this.$router
          .push({
            name: 'symptomReport',
            query: { newSymptom: true }
          })
          .then(() => {
            this.$bus.$emit('toast.display', {
              message: message,
              status: 'warning',
              duration: 5000
            });
          });
        return;
      }
      const s = new SymptomModel({ Name: symptomDefinition.Name });
      const srs = new SymptomReportSymptomModel({
        Symptom: s,
        SymptomId: s.Id
      });
      this.report.Symptoms.push(srs);
      this.$saveTempSymptomReport(this.report);
      this.loadTempReport();
      if (this.report.isFromRemote) {
        this.$router.replace({
          name: 'symptomReportEdit',
          params: { symptomreportid: this.report.Id }
        });
      }
      this.report = new SymptomReportModel(
        this.$unsavedSymptomsReport?.__copy()
      );
      this.$router.push({
        name: 'symptomReportDraw',
        params: {
          symptomreportid: this.report.Id
        },
        query: {
          symptomid: srs.SymptomId
        }
      });
    },
    saveSymptomDefinition() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }

      // Don't create duplicate definitions when they're typed in manually
      if (this.$hasSymptomDefinition(this.symptomDefinition)) {
        this.addSymptom(this.symptomDefinition, true);
        return;
      }

      this.isLoading = true;
      this.$createSymptomDefinition(this.symptomDefinition)
        .then(symptomDefinition => {
          this.addSymptom(symptomDefinition, true);
        })
        .finally(() => (this.isLoading = false));
    },
    cancel() {
      this.$router.back();
    },
    resetForm() {
      this.symptomDefinition = new SymptomDefinitionModel({});
    },
    setLayoutSettings() {
      if (this.isComingFromProm) {
        this.$setPageSettings({ option: 'show_menu', value: false });
      }
    },
    iHaveNoSymptoms() {
      this.iHaveNoSymptomsLoading = true;
      this.$addOrUpdateSymptomReport(this.report)
        .then(() => {
          return this.$router.replace({ name: 'start' });
        })
        .catch(() => {
          this.$bus.$emit('toast.display', {
            message: this.$root.$t('generic.saveFailure'),
            status: 'failure'
          });
        })
        .finally(() => (this.iHaveNoSymptomsLoading = false));
    }
  },
  computed: {
    ...mapGetters('symptoms', {
      $currentSymptoms: SYMPTOM_GETTERS.CURRENT_SYMPTOMS
    }),
    ...mapGetters('symptomreports', {
      $unsavedSymptomsReport: SYMPTOMREPORT_GETTERS.UNSAVED_REPORT
    }),
    ...mapGetters('symptomdefinitions', {
      $hasSymptomDefinition: SYMPTOM_DEFINITION_GETTERS.HAS_SYMPTOM_DEFINITION,
      $snomedSymptomDefinitions:
        SYMPTOM_DEFINITION_GETTERS.SNOMED_SYMPTOM_DEFINITIONS,
      $nonSnomedSymptomDefinitions:
        SYMPTOM_DEFINITION_GETTERS.NON_SNOMED_SYMPTOM_DEFINITIONS
    }),
    ...mapGetters('currentuser', {
      $getActiveSymptomModel: CURRENT_USER_GETTERS.GET_ACTIVE_SYMPTOM_MODEL
    }),
    isComingFromProm() {
      return this.$route.name === 'symptomlist';
    },
    filteredSymptomDefinitions() {
      const symptomDefinitionName = this.symptomDefinition.Name?.toLowerCase();

      if (!symptomDefinitionName || symptomDefinitionName.length < 3) {
        return this.$nonSnomedSymptomDefinitions;
      }

      return this.$snomedSymptomDefinitions.filter(sd =>
        sd.Name?.toLowerCase().match(symptomDefinitionName)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@sass/_variables.scss';

ul.errors {
  margin: 0;
}

.symptom-select {
  &__symptom-definitions li {
    margin-top: 10px;
  }
}

.symptom-definition {
  &__arrow {
    position: absolute;
    right: 30px;
  }
}

.selected {
  color: #666;
}

.mdi-close,
.mdi-chevron-right {
  color: #666;
  font-size: 1em;
}

.info-text {
  font-size: 1.3em;
}

@media only screen and (max-width: 500px) {
  .responsive {
    padding-right: 50px;
    display: inline-block;
    word-wrap: break-word;
  }
}
</style>
