<template>
  <div>
    <div class="container" v-if="report">
      <status-message
        :icon="
          report.isCompleted ? 'mdi-check-circle' : 'mdi-check-circle-outline'
        "
        :message="
          report.isCompleted ? 'Formulär sparat' : 'Påbörjat formulär sparat'
        "
        :data-cy="
          report.isCompleted
            ? 'promreport-message-saved'
            : 'promreport-message-partially-saved'
        "
      ></status-message>
      <div class="message" v-if="!report.isCompleted">
        Du kan när som helst fortsätta att fylla i formuläret.
      </div>

      <followup-actions
        :show-skip="false"
        @followups-available="val => (followupsAvailable = val)"
      ></followup-actions>
    </div>
    <float-content>
      <button-home
        :text="
          followupsAvailable
            ? $t('views.statusReport.noFollowup')
            : $t('links.home')
        "
      ></button-home>
    </float-content>
  </div>
</template>

<script>
import StatusMessage from '@/views/components/StatusMessage';
import FollowupActions from '@/views/StatusReport/components/FollowupActions';
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { PROM_GETTERS } from '@/store/modules/proms';
import { mapGetters } from 'vuex';
import { sortByKey } from '@common/Helpers/sorters';
import completedFollowupMixin from '@/views/StatusReport/mixins/completedFollowupMixin';
export default {
  components: { StatusMessage, FollowupActions },
  mixins: [completedFollowupMixin],
  props: {
    promreportid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      followupsAvailable: true
    };
  },
  computed: {
    ...mapGetters('promreports', {
      $findReport: PROMREPORT_GETTERS.FIND_PROMREPORT,
      $graphData: PROMREPORT_GETTERS.PROM_GRAPH_DATA
    }),
    ...mapGetters('proms', {
      $findProm: PROM_GETTERS.FIND_PROM
    }),
    report() {
      return this.$findReport(this.promreportid);
    },
    prom() {
      if (!this.report) {
        return;
      }
      return this.$findProm(this.report.PromId);
    },
    graphData() {
      return sortByKey(this.$graphData(this.report.PromId), 'date');
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  text-align: center;
  font-size: 1.4em;
  margin: 50px 0;
}
.container {
  height: 100%;
}
</style>
