import { randomString } from '@common/Helpers/strings';
import { Bus } from '@common';
import {
  isAndroid,
  isMobile,
  isChromeOnAppleDevice,
  isEdgeOnAppleDevice,
  isFirefoxOnAppleDevice
} from '@common/Helpers/mobile';
import config from '@common/config';
import { getStoredAuthorization } from '@common/Helpers/auth';
import storage from '@common/Helpers/storage';
import TokenModel from '@common/Models/TokenModel';
import AuthService from '@common/Services/AuthService';
import BankIdService from '@common/Services/BankId/BankIdService';
import PusherService from '@common/Services/PusherService';
import moment from 'moment';
import { APP_ACTIONS, APP_MUTATIONS } from '../app/definitions';
import { AUTH_ACTIONS, AUTH_GETTERS, AUTH_MUTATIONS } from './definitions';
import { getMobileOperatingSystem, ANDROID } from '@common/Helpers/mobile';

class State {
  constructor() {
    /** @type {TokenModel} */
    this.tokenDetails = {};
    this.lastLogin = '';
    this.activeRole = '';
    /** @description This is true when logging in */
    this.loading = false;
    /** @description This is true when token is being refreshed  */
    this.refreshingToken = false;
    /** @description Status of current BankId transaction.  */
    this.bankIdStatus = null;
    /** @description Recommended BankId message shown depending on status/hintCode.  */
    this.bankIdMsg = null;
    /** @description QR Image to display when on desktop. Refreshes with regular intervals. */
    this.bankIdQrImage = null;
    /** @description Indicates whether QR image should be displayed. */
    this.bankIdUseQr = false;
    /** @description */
    this.bankIdAutoStartToken = null;
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  /** Login request */
  [AUTH_MUTATIONS.LOGIN_REQUEST](state) {
    state.loading = true;
  },

  /**
   * Successful login
   * @param {TokenModel} details
   */
  [AUTH_MUTATIONS.LOGIN_SUCCESS](state, details) {
    state.tokenDetails = details;
    state.lastLogin = 'successful';
    state.loading = false;
    state.refreshingToken = false;

    // if (details.remember === true) {
    storage.set('auth', storableAuthData(details), details.expires);
    // }
  },

  /** Login failed */
  [AUTH_MUTATIONS.LOGIN_FAILURE](state) {
    state.loading = false;
  },

  /** Logout */
  [AUTH_MUTATIONS.LOGOUT](state) {
    state.tokenDetails = {};
  },

  /** Refresh token request */
  [AUTH_MUTATIONS.ACCESS_TOKEN_REQUEST](state) {
    state.refreshingToken = true;
  },

  /**
   * Successful token refresh
   * @param {TokenModel} details
   */
  [AUTH_MUTATIONS.ACCESS_TOKEN_SUCCESS](state, details) {
    state.tokenDetails = details;
    state.refreshingToken = false;
    storage.set('auth', storableAuthData(details), details.expires);
  },

  /** Failed token refresh */
  [AUTH_MUTATIONS.ACCESS_TOKEN_FAILURE](state) {
    state.refreshingToken = false;
  },

  /**
   * Consented to required contracts toggle
   * @param {1|0} status
   */
  [AUTH_MUTATIONS.CONSENTED_TO_REQUIRED_CONTRACTS](state, status = 1) {
    state.tokenDetails.consented_to_required_contracts = status;
    storage.set(
      'auth',
      storableAuthData(state.tokenDetails),
      state.tokenDetails.expires
    );
  },

  /**
   * Set role
   * @param {String} role
   */
  [AUTH_MUTATIONS.SET_ACTIVE_ROLE](state, role) {
    if (!role && storage.exists('activeRole')) {
      role = storage.get('activeRole');
    } else if (
      !role &&
      !storage.exists('activeRole') &&
      state.tokenDetails.role
    ) {
      role = state.tokenDetails.role;
    }

    if (role !== undefined && role !== null) {
      state.activeRole = role;
      storage.set('activeRole', role);
    }
  },

  /**
   * Set BankId status
   * @param {String} status
   */
  [AUTH_MUTATIONS.SET_BANKID_STATUS](state, status) {
    state.bankIdStatus = status;
  },

  /**
   * Set BankId msg
   * @param {String} msg
   */
  [AUTH_MUTATIONS.SET_BANKID_MSG](state, msg) {
    state.bankIdMsg = msg;
  },

  [AUTH_MUTATIONS.SET_BANKID_QR_IMAGE](state, url) {
    state.bankIdQrImage = url;
  },

  [AUTH_MUTATIONS.SET_BANKID_USE_QR](state, useQr) {
    state.bankIdUseQr = useQr;
  },

  [AUTH_MUTATIONS.SET_BANKID_AUTO_START_TOKEN](state, autoStartToken) {
    state.bankIdAutoStartToken = autoStartToken;
  },

  /**
   * Reset BankId state
   */
  [AUTH_MUTATIONS.RESET_BANKID_STATE](state) {
    state.bankIdStatus = null;
    state.bankIdMsg = null;
    state.bankIdQrImage = null;
    state.bankIdUseQr = false;
    state.bankIdAutoStartToken = null;
  },

  [AUTH_MUTATIONS.PUSHER_CONNECTED](state) { } // eslint-disable-line
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  /**
   * Auth initialization
   * @param {Boolean} forceInit
   */
  [AUTH_ACTIONS.$AUTHINIT](context, forceInit = false) {
    const savedAuth = getStoredAuthorization();
    if (
      savedAuth &&
      (!savedAuth.twofactor_enabled ||
        (savedAuth.twofactor_enabled && savedAuth.twofactor_authenticated))
    ) {
      context.commit(AUTH_MUTATIONS.LOGIN_SUCCESS, savedAuth);
      if (!config.encryption_key || forceInit === true) {
        return new Promise((resolve, reject) => {
          return context
            .dispatch(AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN)
            .then(token => {
              context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_SUCCESS, token);
              context.commit(
                `app/${APP_MUTATIONS.SET_KEY}`,
                config.encryption_key,
                {
                  root: true
                }
              );

              return context
                .dispatch(`app/${APP_ACTIONS.$PREINIT}`, null, {
                  root: true
                })
                .then(ok => {
                  return resolve(ok);
                })
                .catch(error => reject(error));
            })
            .catch(e => {
              context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_FAILURE, e.toString());
              return reject(e);
            });
        });
      } else {
        context.commit(`app/${APP_MUTATIONS.SET_KEY}`, config.encryption_key, {
          root: true
        });
        context.dispatch(`app/${APP_ACTIONS.$PREINIT}`, null, {
          root: true
        });
        return Promise.resolve();
      }
    } else {
      return Promise.reject(new Error('not logged in'));
    }
  },

  /**
   * Auth initialization only
   * @param {Boolean} forceInit
   */
  [AUTH_ACTIONS.$AUTHINITONLY](context, forceInit = false) {
    const savedAuth = getStoredAuthorization();
    if (
      savedAuth &&
      (!savedAuth.twofactor_enabled ||
        (savedAuth.twofactor_enabled && savedAuth.twofactor_authenticated))
    ) {
      context.commit(AUTH_MUTATIONS.LOGIN_SUCCESS, savedAuth);
      if (!config.encryption_key || forceInit === true) {
        return new Promise((resolve, reject) => {
          return context
            .dispatch(AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN)
            .then(token => {
              context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_SUCCESS, token);
              context.commit(
                `app/${APP_MUTATIONS.SET_KEY}`,
                config.encryption_key,
                {
                  root: true
                }
              );

              return resolve(token);
            })
            .catch(e => {
              context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_FAILURE, e.toString());
              return reject(e);
            });
        });
      } else {
        context.commit(`app/${APP_MUTATIONS.SET_KEY}`, config.encryption_key, {
          root: true
        });
        return Promise.resolve();
      }
    } else {
      return Promise.reject(new Error('not logged in'));
    }
  },

  /**
   * Login user
   */
  [AUTH_ACTIONS.LOGIN](context, loginDetails) {
    context.commit(AUTH_MUTATIONS.LOGIN_REQUEST);

    return AuthService.login(
      loginDetails.username,
      loginDetails.password,
      loginDetails.groupId,
      loginDetails.organizationId,
      loginDetails.roleId
    )
      .then(authData => {
        if (!belongsToTheGroupUsedToLogin(authData, loginDetails)) {
          console.error(
            'Did not find an organization or group belonging to the user! Trying to refresh with existing'
          );
          context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_SUCCESS, authData);

          return context
            .dispatch(AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN, { force: true })
            .then(x => postLoggedIn(context, x));
        }

        return postLoggedIn(context, authData);
      })
      .catch(error => {
        console.log(AUTH_MUTATIONS.LOGIN_FAILURE, error);
        context.commit(AUTH_MUTATIONS.LOGIN_FAILURE);
        throw error;
      });
  },

  /** @param {Boolean} skipDestroy */
  [AUTH_ACTIONS.LOGOUT](context, skipDestroy = false) {
    if (!context.getters[AUTH_GETTERS.TOKEN]) {
      return Promise.resolve();
    }

    if (skipDestroy === true) {
      storage.removeAll();
      context.commit(AUTH_MUTATIONS.LOGOUT);
      Bus.$emit('reset-state');
      return Promise.resolve();
    }

    return AuthService.destroySession(
      context.getters[AUTH_GETTERS.TOKEN].session_id
    ).finally(() => {
      storage.removeAll();
      context.commit(AUTH_MUTATIONS.LOGOUT);
      Bus.$emit('reset-state');
    });
  },

  [AUTH_ACTIONS.START_BANKID_ON_THIS_DEVICE](context) {
    context.commit(AUTH_MUTATIONS.SET_BANKID_USE_QR, false);
    context.dispatch(AUTH_ACTIONS.OPEN_BANKID_APP);
  },

  async [AUTH_ACTIONS.LOGIN_WITH_BANKID](context, loginDetails) {
    // Helper method
    const startBankIdCollect = orderRef => {
      return new Promise((resolve, reject) => {
        return collectBankId(orderRef, (response, error) => {
          if (error) {
            return reject('Unable to login with bankid');
          }

          return resolve(response);
        });
      });
    };

    // Helper method
    const collectBankId = (orderRef, callback, tries = 0) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          return BankIdService.collectBankId(orderRef).then(
            bankIdCollectRsp => {
              const status = bankIdCollectRsp.Status;
              const usesQr = context.state.bankIdQrImage !== null;

              context.commit(
                AUTH_MUTATIONS.SET_BANKID_STATUS,
                bankIdCollectRsp.Status
              );

              context.commit(
                AUTH_MUTATIONS.SET_BANKID_MSG,
                bankIdCollectRsp.getStatusMsg(usesQr)
              );

              if (status === 'complete' || status === 'failed') {
                return resolve(callback(bankIdCollectRsp));
              }

              if (++tries > 20) {
                return reject(
                  callback(
                    null,
                    'exhausted retries - failed to login with bankid'
                  )
                );
              }
              collectBankId(orderRef, callback, tries);
            }
          );
        }, 2000);
      });
    };

    context.commit(AUTH_MUTATIONS.LOGIN_REQUEST);

    const getQr = isMobile() ? false : true;

    var bankIdAuthResponse = await BankIdService.startBankIdAuth(
      loginDetails.organizationId,
      loginDetails.groupId,
      getQr
    );

    context.commit(AUTH_MUTATIONS.SET_BANKID_USE_QR, getQr);
    context.commit(
      AUTH_MUTATIONS.SET_BANKID_AUTO_START_TOKEN,
      bankIdAuthResponse.AutoStartToken
    );
    context.commit(
      AUTH_MUTATIONS.SET_BANKID_QR_IMAGE,
      bankIdAuthResponse.QrImage
    );

    context.dispatch(AUTH_ACTIONS.OPEN_BANKID_APP, bankIdAuthResponse.QrImage);

    return startBankIdCollect(bankIdAuthResponse.OrderRef)
      .then(bankIdCollectRsp => {
        if (bankIdCollectRsp.Status === 'failed') {
          context.commit(AUTH_MUTATIONS.LOGIN_FAILURE);
          return;
        }

        return AuthService.loginWithBankId(
          bankIdCollectRsp.CompletionData.user.personalNumber,
          bankIdCollectRsp.OrderRef,
          loginDetails.roleId,
          loginDetails.groupId,
          loginDetails.organizationId
        ).then(authData => {
          console.log('Logged in with bankid');

          if (!belongsToTheGroupUsedToLogin(authData, loginDetails)) {
            console.error(
              'Did not find an organization or group belonging to the user! Trying to refresh with existing'
            );
            context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_SUCCESS, authData);

            return context
              .dispatch(AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN, {
                force: true
              })
              .then(x => postLoggedIn(context, x));
          }

          return postLoggedIn(context, authData);
        });
      })
      .catch(error => {
        console.log(error);
        context.commit(AUTH_MUTATIONS.LOGIN_FAILURE);

        // Let's re-use the BankId expired transaction error
        // even if it's our own timeout that triggers this...
        context.commit(AUTH_MUTATIONS.SET_BANKID_STATUS, 'failed');
        context.commit(AUTH_MUTATIONS.SET_BANKID_MSG, 'bankid.rfa.8');
        return Promise.reject(error);
      });
  },

  [AUTH_ACTIONS.INIT_PUSHER](context) {
    if (!context.state.tokenDetails.pid) {
      return; // Admin might not have a Patient
    }

    PusherService.subscribe(context.state.tokenDetails.pid, () => {
      context.commit(AUTH_MUTATIONS.PUSHER_CONNECTED);
    });
  },

  /**
   * @param {Object} extras
   * @param {Boolean} extras.force
   * @param {String} extras.organization
   * @param {String} extras.group
   * @param {String} extras.role
   */
  [AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN](context, extras) {
    if (
      !extras?.force &&
      moment(new Date(context.state.tokenDetails.expires)).diff(
        moment(),
        'seconds'
      ) >= 0 &&
      config.encryption_key
    ) {
      return context.state.tokenDetails;
    }

    context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_REQUEST);

    const token = context.state.tokenDetails;
    const activeRole = context.state.activeRole;

    return AuthService.refreshToken(
      token.refresh_token,
      token.refresh_token_secret,
      extras?.role ? extras.role : activeRole || token.role,
      extras?.group ? extras.group : token.group,
      extras?.organization ? extras.organization : token.organization
    )
      .then(authData => {
        context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_SUCCESS, authData);
        context.commit(AUTH_MUTATIONS.SET_ACTIVE_ROLE, authData.role);
        context.dispatch(AUTH_ACTIONS.INIT_PUSHER);
        return authData;
      })
      .catch(error => {
        context.dispatch(AUTH_ACTIONS.LOGOUT, true);
        context.commit(AUTH_MUTATIONS.ACCESS_TOKEN_FAILURE);
        throw error;
      });
  },

  /** @param {String} role */
  [AUTH_ACTIONS.SET_ACTIVE_ROLE]({ commit, dispatch }, role) {
    commit(AUTH_MUTATIONS.SET_ACTIVE_ROLE, role);

    dispatch(
      `auth/${AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN}`,
      {
        force: true
      },
      { root: true }
    ).then(() => {
      return dispatch(`app/${APP_ACTIONS.$PREINIT}`, null, {
        root: true
      });
    });
  },

  [AUTH_ACTIONS.START_BANKID_QR_IMAGE_REFRESHER]({ commit, state }, url) {
    var tries = 0;
    var interval = setInterval(() => {
      const newUrl = `${url}?t=${tries}`;
      commit(AUTH_MUTATIONS.SET_BANKID_QR_IMAGE, newUrl);

      if (++tries === 20 || !state.loading) {
        console.log(`Stopping after ${tries} retries.`);
        clearInterval(interval);
        commit(AUTH_MUTATIONS.SET_BANKID_QR_IMAGE, null);
      }
    }, 2000);
  },
  [AUTH_ACTIONS.OPEN_BANKID_APP]({ commit, dispatch, state }, qrImage) {
    if (state.bankIdUseQr) {
      dispatch(AUTH_ACTIONS.START_BANKID_QR_IMAGE_REFRESHER, qrImage);
    } else {
      let redirect;

      if (isAndroid || !isMobile()) {
        redirect = 'null';
      } else if (isChromeOnAppleDevice) {
        redirect = encodeURIComponent('googlechrome://');
      } else if (isFirefoxOnAppleDevice) {
        redirect = encodeURIComponent('firefox://');
      } else if (isEdgeOnAppleDevice) {
        redirect = encodeURIComponent('microsoft-edge:');
      } else {
        // Random string to append as anchor to make Safari redirect to same view
        // rather than opening a new one and lose state (user wouldn't get redirected).
        // Completely nonsensical but works...
        const someString = randomString(10);
        redirect = encodeURIComponent(`${window.location}#${someString}`);
      }

      if (isAndroid || !isMobile()) {
        window.location.href = `bankid:///?autostarttoken=${state.bankIdAutoStartToken}&redirect=${redirect}`;
      } else {
        window.location.href = `https://app.bankid.com?autostarttoken=${state.bankIdAutoStartToken}&redirect=${redirect}`;
      }
    }
  }
};

store.getters = {
  [AUTH_GETTERS.CONSENTED_TO_REQUIRED_CONTRACTS](state) {
    return state.tokenDetails.consented_to_required_contracts;
  },
  [AUTH_GETTERS.RESPONDED_TO_OPTIONAL_CONTRACTS](state) {
    return state.tokenDetails.responded_to_optional_contracts;
  },
  [AUTH_GETTERS.OUTSTANDING_CONTRACTS](state, getters) {
    return !(
      getters[AUTH_GETTERS.CONSENTED_TO_REQUIRED_CONTRACTS] &&
      getters[AUTH_GETTERS.RESPONDED_TO_OPTIONAL_CONTRACTS]
    );
  },
  [AUTH_GETTERS.TFA](state) {
    return state.tokenDetails.twofactor;
  },
  [AUTH_GETTERS.TFA_ENABLED](state) {
    return state.tokenDetails.twofactor_enabled;
  },
  [AUTH_GETTERS.TFA_ACTIVE](state) {
    return !!state.tokenDetails.twofactor_authenticated;
  },
  [AUTH_GETTERS.SESSION_ID](state) {
    return state.tokenDetails.session_id;
  },
  [AUTH_GETTERS.LOGGED_IN](state) {
    return Object.keys(state.tokenDetails).length > 0;
  },
  [AUTH_GETTERS.ACCOUNT_ACTIVE](state) {
    return state.tokenDetails.deactivated === 0;
  },
  [AUTH_GETTERS.TOKEN](state) {
    return state.tokenDetails;
  },
  [AUTH_GETTERS.ACTIVE_ROLE](state) {
    return state.activeRole;
  },
  [AUTH_GETTERS.ACTIVE_ROLES]: state => appRoles => {
    const token = state.tokenDetails;

    let activeUserRoles = [];

    if (token.parsedGroups !== undefined) {
      const organization = token.parsedGroups.find(
        x => x.OrganizationId == token.organization && x.GroupId == token.group
      );
      activeUserRoles = organization.orderedRoles.map(role => {
        return appRoles ? { Display: appRoles[role], Value: role } : role;
      });
    }

    return activeUserRoles;
  },
  [AUTH_GETTERS.ACTIVE_ROLES_ARRAY]: state => {
    if (
      state.tokenDetails == undefined ||
      state.tokenDetails.parsedGroups == undefined
    ) {
      return [];
    }

    const activeOrg = state.tokenDetails.organization;
    const activeGroup = state.tokenDetails.group;

    const activeGroupRoles = state.tokenDetails.parsedGroups.find(
      g => g.OrganizationId === activeOrg && g.GroupId === activeGroup
    ).orderedRoles;

    return activeGroupRoles;
  },

  [AUTH_GETTERS.BANKID_STATUS]: state => {
    return state.bankIdStatus;
  },

  [AUTH_GETTERS.BANKID_MSG]: state => {
    return state.bankIdMsg;
  },

  [AUTH_GETTERS.BANKID_QR_IMAGE]: state => {
    return state.bankIdQrImage;
  },

  [AUTH_GETTERS.BANKID_USE_QR]: state => {
    return state.bankIdUseQr;
  }
};

export { AUTH_ACTIONS, AUTH_GETTERS, AUTH_MUTATIONS } from './definitions';

export default store;

const storableAuthData = details => {
  const stored = Object.assign({}, details);
  delete stored.encryption_key;
  return details;
};

function belongsToTheGroupUsedToLogin(authData, loginDetails) {
  return authData.parsedGroups.find(
    x =>
      x.OrganizationId === loginDetails.organizationId &&
      x.GroupId === loginDetails.groupId
  );
}

function postLoggedIn(context, authData) {
  context.commit(AUTH_MUTATIONS.LOGIN_SUCCESS, authData);
  context.commit(AUTH_MUTATIONS.SET_ACTIVE_ROLE);
  context.dispatch(AUTH_ACTIONS.INIT_PUSHER);
  if (authData.deactivated === 0 && authData.pid === null) {
    context.dispatch(
      `currentuser/${CURRENT_USER_ACTIONS.CREATE_NEW_PATIENT}`,
      null,
      {
        root: true
      }
    );
  }

  if (context.getters[AUTH_GETTERS.ACTIVE_ROLE]) {
    return context
      .dispatch(`app/${APP_ACTIONS.$PREINIT}`, null, {
        root: true
      })
      .then(ok => authData);
  }
}
