export const AUTH_MUTATIONS = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  ACCESS_TOKEN_REQUEST: 'ACCESS_TOKEN_REQUEST',
  ACCESS_TOKEN_SUCCESS: 'ACCESS_TOKEN_SUCCESS',
  ACCESS_TOKEN_FAILURE: 'ACCESS_TOKEN_FAILURE',
  ACCEPTED_TOS: 'ACCEPTED_TOS',
  SET_ACTIVE_ROLE: 'SET_ACTIVE_ROLE',
  SET_BANKID_MSG: 'SET_BANKID_MSG',
  SET_BANKID_STATUS: 'SET_BANKID_STATUS',
  SET_BANKID_QR_IMAGE: 'SET_BANKID_QR_IMAGE',
  SET_BANKID_USE_QR: 'SET_BANKID_USE_QR',
  SET_BANKID_AUTO_START_TOKEN: 'SET_BANKID_AUTO_START_TOKEN',
  RESET_BANKID_STATE: 'RESET_BANKID_STATE',
  PUSHER_CONNECTED: 'PUSHER_CONNECTED',
  CONSENTED_TO_REQUIRED_CONTRACTS: 'CONSENTED_TO_REQUIRED_CONTRACTS'
};

export const AUTH_ACTIONS = {
  $AUTHINIT: '$AUTHINIT',
  $AUTHINITONLY: '$AUTHINITONLY',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOGIN_WITH_BANKID: 'LOGIN_WITH_BANKID',
  INIT_PUSHER: 'INIT_PUSHER',
  REQUEST_NEW_ACCESS_TOKEN: 'REQUEST_NEW_ACCESS_TOKEN',
  SET_ACTIVE_ROLE: 'SET_ACTIVE_ROLE',
  CONSENTED_TO_REQUIRED_CONTRACTS: 'CONSENTED_TO_REQUIRED_CONTRACTS',
  START_BANKID_QR_IMAGE_REFRESHER: 'START_BANKID_QR_IMAGE_REFRESHER',
  START_BANKID_ON_THIS_DEVICE: 'START_BANKID_ON_THIS_DEVICE',
  OPEN_BANKID_APP: 'OPEN_BANKID_APP'
};

export const AUTH_GETTERS = {
  CONSENTED_TO_REQUIRED_CONTRACTS: 'CONSENTED_TO_REQUIRED_CONTRACTS',
  RESPONDED_TO_OPTIONAL_CONTRACTS: 'RESPONDED_TO_OPTIONAL_CONTRACTS',
  OUTSTANDING_CONTRACTS: 'OUTSTANDING_CONTRACTS',
  TFA: 'TFA',
  TFA_ENABLED: 'TFA_ENABLED',
  TFA_ACTIVE: 'TFA_ACTIVE',
  LOGGED_IN: 'LOGGED_IN',
  SESSION_ID: 'SESSION_ID',
  ACCOUNT_ACTIVE: 'ACCOUNT_ACTIVE',
  TOKEN: 'TOKEN',
  ROLE: 'ROLE',
  ACTIVE_ROLES: 'ACTIVE_ROLES',
  ACTIVE_ROLES_ARRAY: 'ACTIVE_ROLES_ARRAY',
  BANKID_STATUS: 'BANKID_STATUS',
  BANKID_MSG: 'BANKID_MSG',
  BANKID_QR_IMAGE: 'BANKID_QR_IMAGE',
  BANKID_USE_QR: 'BANKID_USE_QR'
};
