import BankIdCompletionDataModel from './BankIdCompletionDataModel';

// @ts-check
export class BankIdCollectResponseModel {
  constructor({ OrderRef, Status, HintCode, CompletionData }) {
    this._modelName = 'BankIdCollectResponse';
    this.OrderRef = OrderRef;
    this.Status = Status;
    this.HintCode = HintCode;
    this.CompletionData = new BankIdCompletionDataModel(CompletionData);
  }

  getStatusMsg(usesQr) {
    var msg = null; // Translation tag

    if (this.Status === 'failed') {
      // FAILED STATES
      if (this.HintCode === 'userCancel') {
        msg = 'bankid.rfa.6';
      } else if (this.HintCode === 'certificateErr') {
        msg = 'bankid.rfa.16';
      } else if (this.HintCode === 'startFailed') {
        msg = !usesQr ? 'bankid.rfa.17a' : 'bankid.rfa.17b';
      } else if (this.HintCode === 'expiredTransaction') {
        msg = 'bankid.rfa.8';
      } else {
        msg = 'bankid.rfa.22';
      }
    } else if (this.Status === 'pending') {
      // PENDING STATES
      if (this.HintCode === 'started') {
        msg = usesQr ? 'bankid.rfa.14a' : 'bankid.rfa.14b';
      } else if (this.HintCode === 'outstandingTransaction') {
        msg = usesQr ? 'bankid.rfa.1' : 'bankid.rfa.13';
      } else if (this.HintCode === 'noClient') {
        msg = 'bankid.rfa.1';
      } else if (this.HintCode === 'userSign') {
        msg = 'bankid.rfa.9';
      } else if (this.HintCode === 'userMrtd') {
        // Not really used in Symptoms...
        msg = 'bankid.rfa.23';
      }
    }

    return msg;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default BankIdCollectResponseModel;
