import { sortByIndex } from '@common/Helpers/sorters';
import { randomString } from '@common/Helpers/strings';
import { maxLength, minLength, required } from '@vuelidate/validators';
import InfoModel from './InfoModel';
import QuestionModel from './QuestionModel';
import StatusReportTypeModel from './StatusReportTypeModel';

// @ts-check
export class PromModel {
  constructor({
    Id = randomString(),
    Language = '',
    Title = '',
    Concept = '',
    StatusReportTypes = [],
    LanguageIds = ['sv'],
    About = '',
    PromTypeOverride,
    Questions = [],
    QuestionCount = 0,
    Infos = [],
    Tags = [],
    StartDate,
    EndDate = null,
    ForceFill = false,
    HideScore = false,
    PrefillPreviousAnswers = false,
    RedirectUri = null,
    FillCount = 0,
    IntervalDays = 0,
    Version = 0,
    OriginalName = '',
    Introduction = '',
    Image = null,
    Saved = true
  }) {
    this._modelName = 'Prom';
    this.Id = Id;
    this.Language = Language;
    this.Title = Title;
    this.Concept = Concept;
    this.StatusReportTypes = StatusReportTypes.map(
      t => new StatusReportTypeModel(t)
    );
    this.LanguageIds = LanguageIds;
    this.About = About;
    this.PromTypeOverride = PromTypeOverride || null;
    this.Questions = Questions.map(q => new QuestionModel(q)).sort(sortByIndex);
    this.QuestionCount =
      (Questions ? Questions.length : false) || QuestionCount;
    this.Infos = Infos.map(i => new InfoModel(i));
    this.Tags = Tags;
    this.StartDate = StartDate;
    this.EndDate = EndDate;
    this.ForceFill = ForceFill;
    this.HideScore = HideScore;
    this.PrefillPreviousAnswers = PrefillPreviousAnswers;
    this.RedirectUri = RedirectUri;
    this.FillCount = FillCount;
    this.IntervalDays = IntervalDays;
    this.Version = Version;
    this.OriginalName = OriginalName;
    this.Introduction = Introduction;
    this.Image = Image;
    this._saved = Saved;
  }

  hasLanguageVersion(languageId) {
    return this.LanguageIds.includes(languageId);
  }

  get questionCount() {
    return this.Questions.filter(question => question.Answers.length > 0)
      .length;
  }

  get shouldGetFullProm() {
    return this.QuestionCount > this.Questions.length;
  }

  get sortedItems() {
    return this.Questions.concat(this.Infos).sort(sortByIndex);
  }

  get itemsByIndex() {
    var items = [];

    this.sortedItems.forEach(sortedItem => {
      if (sortedItem.ShowLastInfo) {
        const info = this.getPreviousInfoText(sortedItem);
        if (info) {
          items.push(info);
        }
      }
      items.push(sortedItem);
    });
    return items;
  }

  getPreviousInfoText(item) {
    const startIndex = this.sortedItems.indexOf(item);

    for (let i = startIndex; i > -1; i--) {
      if (this.sortedItems[i]._modelName === 'Info') {
        const info = new InfoModel({ ...this.sortedItems[i] });
        return info;
      }
    }
    return null;
  }

  get isFirstItemInfo() {
    if (!this.itemsByIndex[0]) {
      return undefined;
    }
    return this.itemsByIndex[0]._modelName === 'Info';
  }

  get isMetaLocked() {
    return this.Version > 0;
  }

  __copy() {
    return JSON.parse(JSON.stringify(this));
  }

  static get validations() {
    return {
      Title: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(512)
      },
      Concept: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(64)
      },
      About: {
        required,
        maxLength: maxLength(1024)
      },
      Id: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32)
      },
      OriginalName: {
        required,
        maxLength: maxLength(128)
      },
      PromTypeOverride: {},
      RedirectUri: {},
      Image: {
        maxLength: maxLength(64)
      },
      Introduction: {
        minLength: minLength(16),
        maxLength: maxLength(1024)
      },
      StartDate: {},
      EndDate: {},
      PrefillPreviousAnswers: {},
      HideScore: {},
      ForceFill: {},
      FillCount: {
        required
      },
      IntervalDays: {
        required
      },
      StatusReportTypes: {},
      Questions: {
        required,
        minLength: minLength(1)
      },
      Tags: {}
    };
  }
}

export default PromModel;
