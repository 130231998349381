<template>
  <card
    v-if="prom"
    :image="promImage"
    image-height="221px"
    :active="active"
    :key="prom.Id"
    :remove-function="removeFunction"
    @click="goToLink"
  >
    <template v-slot:header>
      <span :title="prom.Concept">{{ prom.Concept }}</span>
    </template>
    <template v-slot:text>{{ prom.About }}</template>
    <template v-slot:meta>
      {{ prom.QuestionCount }} {{ $tc('plural.question', 2) }} &bull;
      {{ prom.Id }}
    </template>
  </card>
</template>

<script>
import {
  PROMREPORT_GETTERS,
  PROMREPORT_ACTIONS
} from '@/store/modules/promreports';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    useActive: {
      type: Boolean
    },
    prom: {
      required: true
    },
    removeFunction: {
      type: Function
    }
  },
  data() {
    return {
      active: true,
      removeLoading: false
    };
  },
  methods: {
    goToLink() {
      if (this.active) {
        this.$router.push(this.resumeOrNew);
      }
    },
    ...mapActions('promreports', {
      $getMyPromReports: PROMREPORT_ACTIONS.GET_MY_PROMREPORTS
    }),
    remove() {
      if (!this.removeFunction) {
        return;
      }
      this.removeLoading = true;
      this.removeFunction()
        .catch(error => {
          this.$getMyPromReports();
        })
        .finally(() => {
          this.removeLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters('promreports', {
      $incompletePromReports: PROMREPORT_GETTERS.FIND_INCOMPLETE_PROMREPORTS
    }),
    resumeOrNew() {
      const newLink = {
        name: 'promReportEdit',
        params: { promid: this.prom.Id }
      };

      if (this.useActive === false) {
        return newLink;
      }

      const incompleteReport = this.$incompletePromReports.find(
        report => report.PromId === this.prom.Id
      );
      if (incompleteReport) {
        return {
          name: 'promReportEdit',
          params: { promid: this.prom.Id, promreportid: incompleteReport.Id }
        };
      } else {
        return newLink;
      }
    },
    promImage() {
      if (!this.prom.Image) {
        return '/images/beach.png';
      }
      return `/images/proms/${this.prom.Image}`;
    }
  }
};
</script>

<style></style>
