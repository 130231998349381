import { APP_ACTIONS, APP_GETTERS } from '@/store/modules/app';
import { AUTH_ACTIONS, AUTH_GETTERS } from '@/store/modules/auth';
import { NEWS_GETTERS } from '@/store/modules/news';
import { PROMREPORT_GETTERS } from '@/store/modules/promreports';
import { STATUSREPORT_GETTERS } from '@/store/modules/statusreports';
import { SYMPTOMREPORT_GETTERS } from '@/store/modules/symptomreports';
import { LANGUAGE_STORAGE } from '@common/constants';
import * as languageHelpers from '@common/Helpers/language';
import { sortByCreatedDate, sortByKey } from '@common/Helpers/sorters';
import storage from '@common/Helpers/storage';
import AppVersionModel from '@common/Models/AppVersionModel';
import LoadingManager from '@common/Modules/quincy/src/library/LoadingManager.js';
import { QUINCYMUTATIONS } from '@common/Modules/quincy/src/store/quincy';
import AuthService from '@common/Services/AuthService';
import GroupService from '@common/Services/Groups/GroupService';
import PatientService from '@common/Services/Patients/PatientService';
import PromRequestService from '@common/Services/PromRequests/PromRequestService';
import UserService from '@common/Services/User/UserService';
import ViewService from '@common/Services/Views/ViewService';
import { UpdatePatientRequest } from '@common/Services/Patients/mappers/PatientRequests';
import moment from 'moment';
import {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS,
  CURRENT_USER_MUTATIONS
} from './definitions';

class State {
  constructor() {
    this.language = 'sv';
    this.profile = null;
    this.user = null;
    this.symptomModel = null;
    this.shareSettings = [];
    this.requests = [];
    this.activeGroup = null;
    this.myGroups = [];
    this.tfaNeeded = 0;
    this.activeSessions = [];
    this.promrequests = [];
    this.appVersion = null;
    this.autoScroll = true;
    this.advancedManikin = false;
  }
}

const store = {
  namespaced: true,
  state: new State(),
  mutations: {},
  actions: {},
  getters: {}
};

/** @type {import('vuex').MutationTree<typeof store.state>} */
store.mutations = {
  [CURRENT_USER_MUTATIONS.SET_LANGUAGE](state, language) {
    state.language = language.LanguageId;
    if (language.LanguageId) {
      storage.set(LANGUAGE_STORAGE, language.LanguageId);
    }
  },

  [CURRENT_USER_MUTATIONS.SET_MODEL](state, modelId) {
    if (modelId !== undefined) {
      state.symptomModel = modelId;
      storage.set('user/model', modelId);
    }
  },

  [CURRENT_USER_MUTATIONS.SET_APP_VERSION](state, appVersion) {
    if (appVersion === undefined) {
      appVersion = {};
    }
    state.appVersion = new AppVersionModel(appVersion);
    storage.set('user/appVersion', state.appVersion);
  },

  [CURRENT_USER_MUTATIONS.ADD_SHARE](state, payload) {
    state.shareSettings.push(payload.id);
    storage.setEncrypted('user/shareSettings', state.shareSettings);
  },

  [CURRENT_USER_MUTATIONS.REMOVE_SHARE](state, payload) {
    const index = state.shareSettings.indexOf(payload.id);
    state.shareSettings.splice(index, 1);
    storage.setEncrypted('user/shareSettings', state.shareSettings);
  },

  [CURRENT_USER_MUTATIONS.SET_ACTIVE_SESSIONS](state, sessions) {
    state.activeSessions = sessions;
  },

  [CURRENT_USER_MUTATIONS.SET_PROFILE](state, profile) {
    delete profile._modelName;
    state.profile = profile;
    storage.setEncrypted('user/profile', profile);
  },

  [CURRENT_USER_MUTATIONS.SET_USER](state, user) {
    state.user = user;
  },

  [CURRENT_USER_MUTATIONS.SET_ACTIVE_GROUP](state, group) {
    state.activeGroup = group;
  },

  [CURRENT_USER_MUTATIONS.SET_MY_GROUPS](state, groups) {
    state.myGroups = groups;
  },

  [CURRENT_USER_MUTATIONS.SET_PROMREQUESTS](state, promrequests) {
    state.promrequests = promrequests;
  },

  [CURRENT_USER_MUTATIONS.REMOVE_PROMREQUEST](state, PromId) {
    const index = state.promrequests.findIndex(req => req.PromId === PromId);
    if (index === -1) {
      return;
    }
    state.promrequests.splice(index, 1);
  },

  [CURRENT_USER_MUTATIONS.TOGGLE_2FA](state, tfaState) {
    state.tfaNeeded = tfaState;
  },

  [CURRENT_USER_MUTATIONS.REMOVE_SESSION](state, sessionId) {
    const index = state.activeSessions.findIndex(
      session => session.Id === sessionId
    );
    if (index === -1) {
      return;
    }
    state.activeSessions.splice(index, 1);
  },

  [CURRENT_USER_MUTATIONS.TOGGLE_AUTO_SCROLL](state) {
    state.autoScroll = !state.autoScroll;
  },
  [CURRENT_USER_MUTATIONS.ADD_PROMREQUEST](state, payload) {
    state.promrequests.push(payload);
  }
};

/** @type {import('vuex').ActionTree<typeof store.state>} */
store.actions = {
  async [CURRENT_USER_ACTIONS.$PREINIT]({
    commit,
    dispatch,
    state,
    rootGetters
  }) {
    await dispatch(CURRENT_USER_ACTIONS.GET_USER);
    const activeRole = rootGetters[`auth/${AUTH_GETTERS.ACTIVE_ROLE}`];

    if (activeRole === 'pat') {
      // TODO: Should really look for permission "CanViewOwnData" instead...
      await dispatch(CURRENT_USER_ACTIONS.GET_PROFILE);
      await dispatch(CURRENT_USER_ACTIONS.GET_PROMREQUESTS);
    }

    const storedShareSettings = storage.getEncrypted('user/shareSettings');
    if (storedShareSettings) {
      storedShareSettings.forEach(setting =>
        commit(CURRENT_USER_MUTATIONS.ADD_SHARE, { id: setting })
      );
    }

    const storedModel = storage.get('user/model');
    if (storedModel) {
      await dispatch(CURRENT_USER_ACTIONS.CHANGE_SYMPTOM_MODEL, {
        newModel: storedModel
      });
    }

    await dispatch(CURRENT_USER_ACTIONS.GET_MY_GROUPS).then(groups => {
      const token = rootGetters[`auth/${AUTH_GETTERS.TOKEN}`];
      const group = groups.find(
        g => g.Id === token.group && g.OrganizationId === token.organization
      );

      commit(CURRENT_USER_MUTATIONS.SET_ACTIVE_GROUP, group);
    });
  },
  [CURRENT_USER_ACTIONS.GET_PROFILE_VIEW]({ commit }) {
    return ViewService.getProfile().then(profile => {
      commit(CURRENT_USER_MUTATIONS.SET_PROFILE, profile.Patient);
      commit(CURRENT_USER_MUTATIONS.SET_USER, profile.User);
    });
  },
  [CURRENT_USER_ACTIONS.GET_PROFILE](
    { commit, rootGetters, state, dispatch },
    profile
  ) {
    if (profile) {
      return Promise.resolve(action(profile));
    }
    return PatientService.getMyPatient()
      .then(profile => {
        action(profile);
        return profile;
      })
      .catch(error => {
        const token = rootGetters[`auth/${AUTH_GETTERS.TOKEN}`];
        if (error.ErrorCode === 1004 && token.pid === undefined) {
          dispatch(CURRENT_USER_ACTIONS.CREATE_NEW_PATIENT);
        }
        throw error;
      });

    function action(profile) {
      commit(CURRENT_USER_MUTATIONS.SET_APP_VERSION, profile.AppVersion);

      commit(CURRENT_USER_MUTATIONS.SET_PROFILE, profile);
      const model = rootGetters[`app/${APP_GETTERS.AVAILABLE_MODELS}`].find(
        m => m.Id === profile.Model
      );
      commit(CURRENT_USER_MUTATIONS.SET_MODEL, model.Id);

      commit(`quincy/${QUINCYMUTATIONS.SET_MODEL}`, profile.Model, {
        root: true
      });

      dispatch(CURRENT_USER_ACTIONS.SET_LANGUAGE, {
        LanguageId: profile.Language
      });
      return profile;
    }
  },

  [CURRENT_USER_ACTIONS.GET_USER]({ commit, dispatch }, user) {
    if (user) {
      return commit(CURRENT_USER_MUTATIONS.SET_USER, user);
    }

    UserService.myUser().then(user => {
      commit(CURRENT_USER_MUTATIONS.SET_USER, user);
    });
  },

  [CURRENT_USER_ACTIONS.GET_MY_GROUPS]({ commit }) {
    return GroupService.getMyGroups().then(groups => {
      commit(CURRENT_USER_MUTATIONS.SET_MY_GROUPS, groups);
      return groups;
    });
  },

  [CURRENT_USER_ACTIONS.SET_ACTIVE_GROUP](
    { commit, dispatch },
    { group, role = null }
  ) {
    commit(CURRENT_USER_MUTATIONS.SET_ACTIVE_GROUP, group);
    let newRole;

    if (!role) {
      newRole = group.Roles[0];
    } else {
      newRole = group.Roles.find(r => r === role);
      if (!newRole) {
        throw `User does not have role ${role} in group ${group}.`;
      }
    }

    return dispatch(
      `auth/${AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN}`,
      {
        organization: group.OrganizationId,
        group: group.Id,
        role: newRole,
        force: true
      },
      { root: true }
    ).then(() => {
      return dispatch(`app/${APP_ACTIONS.$PREINIT}`, null, {
        root: true
      });
    });
  },

  [CURRENT_USER_ACTIONS.UPDATE_PROFILE]({ commit, state }, profile) {
    if (profile.AreaCode === '') {
      profile.AreaCode = null;
      profile.County = null;
      profile.Municipal = null;
      profile.City = null;
    }
    const newProfile = UpdatePatientRequest({
      Language: state.language,
      Model: state.symptomModel,
      ...profile
    });
    return PatientService.updateMyPatient(newProfile).then(profile => {
      commit(CURRENT_USER_MUTATIONS.SET_PROFILE, profile);
      return profile;
    });
  },

  [CURRENT_USER_ACTIONS.SET_HAS_SEEN_TUTORIAL]({ commit, state }, tutorial) {
    const profile = state.profile.__copy();

    if (profile.TutorialsSeen.includes(tutorial)) {
      return;
    }

    profile.TutorialsSeen.push(tutorial);

    const newProfile = UpdatePatientRequest({
      ...profile
    });
    return PatientService.updateMyPatient(newProfile).then(profile => {
      commit(CURRENT_USER_MUTATIONS.SET_PROFILE, profile);
      return profile;
    });
  },

  [CURRENT_USER_ACTIONS.UPDATE_USER]({ commit, state, dispatch }, user) {
    const newUser = state.user.Personnummer === undefined;
    return UserService.updateMyUser(user).then(user => {
      commit(CURRENT_USER_MUTATIONS.SET_USER, user);
      if (newUser) {
        dispatch(CURRENT_USER_ACTIONS.GET_PROFILE).then(profile => {
          if (profile.Gender !== profile.Model) {
            dispatch(CURRENT_USER_ACTIONS.CHANGE_SYMPTOM_MODEL, {
              newModel: profile.Gender,
              updateProfile: true
            });
          }
        });
      }
      return user;
    });
  },

  [CURRENT_USER_ACTIONS.GET_ACTIVE_SESSIONS]({ commit }) {
    return AuthService.getActiveSessions().then(sessions => {
      sessions = sortByCreatedDate(sessions).reverse();
      commit(CURRENT_USER_MUTATIONS.SET_ACTIVE_SESSIONS, sessions);
      return sessions;
    });
  },

  [CURRENT_USER_ACTIONS.DESTROY_SESSION]({ commit }, sessionId) {
    return AuthService.destroySession(sessionId).then(
      commit(CURRENT_USER_MUTATIONS.REMOVE_SESSION, sessionId)
    );
  },

  [CURRENT_USER_ACTIONS.LOGOUT_OTHER_DEVICES]({ store, rootState, dispatch }) {
    const activeSessionId = rootState.auth.tokenDetails.session_id;
    return AuthService.logOutFromOtherDevices(activeSessionId).then(() =>
      dispatch(CURRENT_USER_ACTIONS.GET_ACTIVE_SESSIONS)
    );
  },

  [CURRENT_USER_ACTIONS.SET_LANGUAGE]({ commit, dispatch }, language = {}) {
    if (!language.LanguageId) {
      if (storage.exists(LANGUAGE_STORAGE)) {
        language = {
          LanguageId: storage.get(LANGUAGE_STORAGE)
        };
      } else {
        return;
      }
    }
    languageHelpers.loadLanguageAsync(language.LanguageId);
    commit(CURRENT_USER_MUTATIONS.SET_LANGUAGE, language);
  },

  [CURRENT_USER_ACTIONS.CHANGE_LANGUAGE](
    { commit, state, dispatch },
    { language = 'sv', updateProfile = false }
  ) {
    languageHelpers.loadLanguageAsync(language);
    moment.locale(language);

    if (updateProfile) {
      dispatch(CURRENT_USER_ACTIONS.UPDATE_PROFILE, {
        ...state.profile,
        Language: language
      });
    }

    commit(CURRENT_USER_MUTATIONS.SET_LANGUAGE, { LanguageId: language });
  },

  [CURRENT_USER_ACTIONS.CHANGE_SYMPTOM_MODEL](
    { commit, rootState, dispatch, state },
    { newModel, updateProfile = false }
  ) {
    const model = rootState.app.availableModels.find(
      model => model.Id === newModel
    );

    LoadingManager.loadObject(`/${model.Model}`);

    if (updateProfile) {
      dispatch(CURRENT_USER_ACTIONS.UPDATE_PROFILE, {
        ...state.profile,
        Model: newModel
      });
    }

    commit(CURRENT_USER_MUTATIONS.SET_MODEL, newModel);
  },

  [CURRENT_USER_ACTIONS.TOGGLE_SHARE_SETTING]({ commit, state }, setting) {
    const index = state.shareSettings.indexOf(setting.id);
    if (index === -1) {
      commit(CURRENT_USER_MUTATIONS.ADD_SHARE, setting);
    } else {
      commit(CURRENT_USER_MUTATIONS.REMOVE_SHARE, setting);
    }
  },

  [CURRENT_USER_ACTIONS.REMOVE_ACCOUNT]({ dispatch }) {
    return UserService.removeAccount();
  },

  [CURRENT_USER_ACTIONS.ANONYMISE_ACCOUNT]({ dispatch }, anonymiseAccountData) {
    return UserService.anonymiseAccount(anonymiseAccountData);
  },

  [CURRENT_USER_ACTIONS.ACTIVATE_ACCOUNT]({ dispatch }, { Password, Salt }) {
    return UserService.activateAccount(Password, Salt).then(() => {
      dispatch(
        `auth/${AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN}`,
        { force: true },
        {
          root: true
        }
      ).then(() => {
        dispatch(`app/${APP_ACTIONS.$PREINIT}`, null, { root: true });
      });
    });
  },

  [CURRENT_USER_ACTIONS.DEACTIVATE_ACCOUNT]({ commit }, password) {
    return UserService.deactivateAccount(password);
  },

  [CURRENT_USER_ACTIONS.CREATE_NEW_PATIENT]({ dispatch }) {
    return UserService.createNewPatient()
      .then(() =>
        dispatch(
          `auth/${AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN}`,
          { force: true },
          {
            root: true
          }
        )
      )
      .then(() => {
        dispatch(`app/${APP_ACTIONS.$PREINIT}`, null, { root: true });
      });
  },

  [CURRENT_USER_ACTIONS.GET_PROMREQUESTS]({ commit }, promrequests) {
    if (promrequests) {
      return commit(CURRENT_USER_MUTATIONS.SET_PROMREQUESTS, promrequests);
    }
    PromRequestService.getMyPromRequests().then(promrequests =>
      commit(CURRENT_USER_MUTATIONS.SET_PROMREQUESTS, promrequests)
    );
  },

  [CURRENT_USER_ACTIONS.GET_PROMREQUEST]({ commit }, promrequestId) {
    PromRequestService.getRequest(promrequestId).then(promrequest =>
      commit(CURRENT_USER_MUTATIONS.ADD_PROMREQUEST, promrequest)
    );
  },

  [CURRENT_USER_ACTIONS.GET_2FA_SETUP]() {
    return UserService.get2faSetup();
  },

  [CURRENT_USER_ACTIONS.REMOVE_2FA]({ dispatch }) {
    return UserService.remove2fa().then(() => {
      return dispatch(
        `auth/${AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN}`,
        { force: true },
        {
          root: true
        }
      );
    });
  },

  [CURRENT_USER_ACTIONS.REFRESH_2FA]() {
    return UserService.refresh2fa();
  },

  [CURRENT_USER_ACTIONS.AUTHENTICATE_2FA]({ dispatch }, { Code, SessionId }) {
    return UserService.authenticate2fa(Code, SessionId).then(() => {
      return dispatch(
        `auth/${AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN}`,
        { force: true },
        {
          root: true
        }
      );
    });
  },

  [CURRENT_USER_ACTIONS.AUTHENTICATE_2FA_BACKUP_CODE](
    { dispatch },
    { BackupCode, SessionId }
  ) {
    return UserService.authenticate2faBackupCode(BackupCode, SessionId).then(
      () => {
        return dispatch(
          `auth/${AUTH_ACTIONS.REQUEST_NEW_ACCESS_TOKEN}`,
          { force: true },
          {
            root: true
          }
        );
      }
    );
  },

  [CURRENT_USER_ACTIONS.DOWNLOAD_DATA]() {
    return UserService.downloadData();
  },

  [CURRENT_USER_ACTIONS.TRIGGER_2FA_MODAL]({ commit }, modal = 1) {
    commit(CURRENT_USER_MUTATIONS.TOGGLE_2FA, modal);
    setTimeout(() => {
      commit(CURRENT_USER_MUTATIONS.TOGGLE_2FA, 0);
    }, 500);
  }
};

/** @type {import('vuex').GetterTree<typeof store.state>} */
store.getters = {
  [CURRENT_USER_GETTERS.APP_VERSION](state) {
    return state.appVersion;
  },
  [CURRENT_USER_GETTERS.PROFILE](state) {
    return state.profile;
  },
  [CURRENT_USER_GETTERS.USER](state) {
    return state.user;
  },

  [CURRENT_USER_GETTERS.GET_ACTIVE_SYMPTOM_MODEL](
    state,
    getters,
    rootState,
    rootGetters
  ) {
    return rootGetters[`app/${APP_GETTERS.AVAILABLE_MODELS}`].find(
      model => model.Id === state.symptomModel
    );
  },

  [CURRENT_USER_GETTERS.GET_SYMPTOM_MODEL]:
    (state, getters, rootState, rootGetters) => requestedModel => {
      return rootGetters[`app/${APP_GETTERS.AVAILABLE_MODELS}`].find(
        model => model.Id === requestedModel
      );
    },

  [CURRENT_USER_GETTERS.ACTIVE_LANGUAGE](state) {
    return state.language;
  },

  [CURRENT_USER_GETTERS.PROFILE_COPY](state) {
    if (!state.profile) {
      return {};
    }
    return state.profile.__copy();
  },

  [CURRENT_USER_GETTERS.USER_COPY](state) {
    if (!state.user) {
      return;
    }
    return state.user.__copy();
  },

  [CURRENT_USER_GETTERS.ACTIVE_SYMPTOM_TYPES](state) {
    return state.activeGroup.SymptomTypes;
  },

  [CURRENT_USER_GETTERS.ACTIVE_SESSIONS](state) {
    return sortByKey(state.activeSessions, 'LatestLogin').reverse();
  },

  [CURRENT_USER_GETTERS.SHARE_SETTINGS](state) {
    return state.shareSettings;
  },
  [CURRENT_USER_GETTERS.ACTIVE_GROUP](state) {
    return state.activeGroup;
  },
  [CURRENT_USER_GETTERS.MY_GROUPS](state) {
    return state.myGroups;
  },
  [CURRENT_USER_GETTERS.MY_GROUPS_WITH_PATIENT_ROLE](state, getters) {
    var myGroups = state.myGroups.filter(g =>
      g.Roles.find(role => role === 'pat')
    );

    return myGroups;
  },
  [CURRENT_USER_GETTERS.PROMREQUESTS](state) {
    return state.promrequests;
  },
  [CURRENT_USER_GETTERS.INCOMPLETE_PROMREQUESTS](state, getters) {
    return getters[CURRENT_USER_GETTERS.PROMREQUESTS].filter(
      req => req.ReportDate === (undefined || null)
    );
  },
  [CURRENT_USER_GETTERS.IS_PROMREQUEST]: (state, getters) => PromId => {
    return !!state.promrequests.find(req => req.PromId === PromId);
  },
  [CURRENT_USER_GETTERS.TFA_NEEDED](state) {
    return state.tfaNeeded;
  },
  [CURRENT_USER_GETTERS.STATS](state, getters, rootState, rootGetters) {
    const lastLogin = moment(
      rootGetters[`auth/${AUTH_GETTERS.TOKEN}`].last_login
    );
    const promreports =
      rootGetters[`promreports/${PROMREPORT_GETTERS.PROM_REPORTS}`].length;
    const symptomreports =
      rootGetters[`symptomreports/${SYMPTOMREPORT_GETTERS.SYMPTOM_REPORTS}`]
        .length;
    const statusreports =
      rootGetters[`statusreports/${STATUSREPORT_GETTERS.STATUS_REPORTS}`]
        .length;

    const latestNews = rootGetters[`news/${NEWS_GETTERS.NEWS_ITEMS}`];
    const newsItemsSinceLastLogin = latestNews.filter(
      n => n.PublishDate > lastLogin
    ).length;

    return {
      lastLogin,
      reports: promreports + symptomreports + statusreports,
      newsItemsSinceLastLogin: newsItemsSinceLastLogin
    };
  },
  [CURRENT_USER_GETTERS.HAS_SEEN_TUTORIAL]: state => tutorial => {
    return state.profile.TutorialsSeen.includes(tutorial);
  },
  [CURRENT_USER_GETTERS.AUTO_SCROLL](state) {
    return state.autoScroll;
  },
  [CURRENT_USER_GETTERS.ADVANCED_MANIKIN](state) {
    return state.advancedManikin;
  }
};

export {
  CURRENT_USER_ACTIONS,
  CURRENT_USER_GETTERS,
  CURRENT_USER_MUTATIONS
} from './definitions';

export default store;
